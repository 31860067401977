body.campus_licenses {
  background-color: $dark-bg-blue;

  & .middlebar {
    display: none;
  }

  & .l-hero {
    padding: 0 0 40px;
  }

  & .page-title {
    text-align: center;
    margin: 0 0 40px;
    max-width: 100%;

    @media #{$sm} {
      margin: 0 0 30px;
      font-size: $headlines-font-size;
    }
  }

  & .section-title {
    color: $white;
    margin-bottom: 10px;
    font-size: $lead-font-size;
  }

  & .licence-info__grid {
    display: grid;
    grid-template: auto auto / repeat(3, 1fr);
    gap: 10px;
    color: $gray-text;
    margin-bottom: 40px;

    @media #{$xs} {
      grid-template: unset;
      grid-auto-flow: rows;
      margin-bottom: 30px;
    }
  }

  & .licence-info__item {
    background-color: $white;
    border-radius: 3px;
    padding: 30px 20px;
    text-align: center;

    @media #{$sm} {
      padding: 20px;
    }

    &.warning {
      & .figure {
        color: $red;
      }
    }

    &.full-width {
      grid-column: 1/-1;
      padding: 20px;
      text-align: left;
      display: flex;

      @media #{$sm} {
        display: block;
        text-align: center;
      }
    }

    & .actions {
      display: flex;
      flex: 0 0 auto;
      padding-top: 15px;
      gap: 4px;

      @media #{$sm} {
        justify-content: center;
      }

      & .btn {
        margin: 0;
      }

      & .btn + .btn {
        margin: 0;
      }
    }

    & .content {
      margin-right: auto;
    }

    & .figure {
      font-family: var(--light);
      color: $logo-blue;
      font-size: $header-headline-font-size;

      @media #{$sm} {
        font-size: $headlines-font-size;
      }
    }

    & .name {
      font-size: $standard-font-size;
      margin-bottom: 4px;
    }

    & .domain-list {
      & p {
        font-size: $tiny-font-size;
        line-height: 1.4;
        color: $gray-text-light;
        margin: 0;
        display: inline;
      }

      & .load-more {
        background-color: transparent;
        color: $logo-blue;
        font-family: var(--medium);
        border: 0;
        padding: 0;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  & .licence-table {
    background-color: $white;
    padding: 20px 15px;
    border-radius: 3px;
  }

  & .licence-table__header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-border;
    margin-bottom: 20px;

    @media #{$sm} {
      flex-direction: column;
      align-items: flex-start;
    }

    & .licence-table__search-input {
      margin-right: 8px;
      padding-right: 40px;
      min-width: 300px;

      @media #{$sm} {
        min-width: 0;
      }
    }

    .licence-table__topbar {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      flex-wrap: wrap;
    }

    & .licence-table__search {
      color: $gray-text;
      font-size: $tiny-font-size;

      form {
        margin: 0;
        position: relative;

        button {
          position: absolute;
          z-index: 1;
          border: 0;
          background: transparent;
          top: 0;
          right: 0;
          bottom: 0;
          aspect-ratio: 1;
          color: $gray-text-lightest;
          font-size: 20px;
        }
      }
    }

    .licence-table__result {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: auto;

      & strong {
        margin-right: 4px;
        color: $gray-text;
      }

      & .licence-table__download {
        color: $gray-text;

        a {
          color: $gray-text;
          position: relative;

          button {
            z-index: 1;
            border: 0;
            background: transparent;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            aspect-ratio: 1;
            color: $gray-text;
            font-size: 20px;
          }
        }
      }
    }
  }

  & .licence-table__table {
    width: 100%;
    overflow-x: auto;

    table {
      font-size: $tiny-font-size;
      color: $gray-text;
      text-align: left;
      width: 100%;
      border-collapse: collapse;

      & thead tr {
        background-color: transparent !important;
      }

      & th {
        font-family: var(--medium);
        font-weight: normal
      }

      td,
      th {
        padding: 10px 5px;
        min-width: 80px;

        &:last-child {
          text-align: right;
        }

        &.premium-icon {
          display: flex;
          justify-content: center;
        }
      }

      td {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .btn {
          white-space: nowrap;
        }

        .premium-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          overflow: hidden;
          height: 16px;
          position: relative;
  
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      & tr:nth-of-type(2n+1) {
        background-color: $gray-bg;
      }
    }
  }
}
