.contentbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:not(:first-child) {
    .small {
      margin-top: 20px;
      display: inline-block;
    }
  }

  // taken from https://stackoverflow.com/a/13184714
  .anchor {
    @media #{$lg} {
      display: block;
      position: relative;
      top: -80px;
      visibility: hidden;
    }
  }

  > .hide + li > .small, // if hide before the first subheading
  ol li:first-child .small {  // subheadings on atlas/all (nested list)
    margin-top: 0;
  }

  ol, ul {
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0;
  }

  li {
    position: relative;

    &:last-child {
      clear: both;
    }
  }

  &--truncated {
    max-height: 306px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.1s linear;

    &:after {
      cursor: pointer;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 150px;
      background-image: linear-gradient(to bottom, transparent, #fff);
    }

    &:hover {
      max-height: 380px;
      transition: max-height 0.1s linear;

      &:after {
        height: 220px;
      }
    }
  }
}

// defines the height of the loading frame before e.g. the contentbox for the
// embeddded  video is loaded
.contentbox-container.spinner {
  min-height: 102px;
}

.contentbox {
  width: 100%;
  min-height: 102px;
  border: 1px solid $gray-border;
  background-color: $gray-bg-dark;
  border-radius: 2px;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: row;

  &:hover {
    border: 1px solid #b8b8b8;
  }

  & > a {
    text-decoration: none;
  }

  .contentbox-image {
    width: 101px;
    background-color: #ffffff;
    position: relative;
    border-right: 1px solid $gray-border;

    img {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: absolute;
    }

    @media #{$sm} {
      display: none;
    }

  }

  .contentbox-topic {
    background-color: $gray-bg-dark;
    padding: 0 30px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;


    & > .contentbox__new_de,
    & > .contentbox__new_en,
    & > .contentbox__new_pt,
    & > .contentbox__new_es {
      z-index: 4;
      height: 30px;
      width: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }

    & > .contentbox__new_de {
      background: transparent url("~images/content/new_indicator_de.webp") no-repeat scroll center center/30px 30px;
    }

    & > .contentbox__new_en {
      background: transparent url("~images/content/new_indicator_en.webp") no-repeat scroll center center/30px 30px;
    }

    & > .contentbox__new_pt {
      background: transparent url("~images/content/new_indicator_pt.webp") no-repeat scroll center center/30px 30px;
    }

    & > .contentbox__new_es {
      background: transparent url("~images/content/new_indicator_es.webp") no-repeat scroll center center/30px 30px;
    }

    @media #{$xs} {
      padding: 20px;
    }

    & .contentbox-title {
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 27px;
      margin-top: 0;
      font-family: var(--regular);
      color: $logo-blue;
      font-weight: normal;
      display: flex;

      & > .fa {
        font-size: 18px;
        vertical-align: baseline;
        line-height: 23px;
        margin-right: 6px;
      }
    }

    & .contentbox-description {
      font-family: var(--light);
      color: $gray-text;
      font-size: 13px;
      line-height: 22px;
      margin-top: 0;

      & .badge.badge--small {
        font-size: 9px;
        min-height: 12px;
        line-height: 14px;
        padding: 0 7px;
        margin-right: 4px;
        margin-top: -1px;
      }
    }
  }

  &--highlight {
    border: 1px solid $logo-blue-light;
    background-color: $logo-blue-bg;

    & .contentbox-image {
      border-right: 1px solid $logo-blue-lightest;
    }

    & .contentbox-topic {
      background-color: $logo-blue-bg;
    }
  }
}

.grid > .columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  clear: both;

  & > * {
    flex-basis: calc(50% - 8px);
    margin-top: 15px;
    margin-bottom: 0;

    &:nth-child(1), &:nth-child(2) {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin-left: 15px;
    }
  }

  @media #{$sm} {
    display: block;

    & > * {
      width: 100%;

      &:nth-child(1), &:nth-child(2) {
        margin-top: 15px;
      }

      &:nth-child(2n) {
        margin-left: 0;
      }
    }
  }

  & .contentbox {

    & > .contentbox-image {

      @media #{$sm} {
        display: block;
      }
    }

    & > .contentbox-topic {
      padding: 0 15px;

      & > .contentbox-title {
        line-height: 16px;
        font-size: 13px;
        margin-bottom: 7px;
      }

      & > .contentbox-description {
        font-size: 12px;
        line-height: 12px;
        font-family: var(--regular);
        text-decoration: underline;

        & > a {
          color: $gray-text;
        }
      }
    }
  }
}

// A/B test -

.contentbox--featured,
.contentbox-badge,
.contentbox-meta {
  display: none;
}

.ab-test-improved-study-units {
  .grid > .columns:empty {
    display: none;
  }

  .contentbox--featured {
    display: block;
  }

  .contentbox--featured .contentbox {
    width: 100%;
    border: 1px solid $logo-blue-light;
    background-color: $logo-blue-bg;
    border-radius: 2px;
    position: relative;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    transition: all .45s ease;

    &:hover {
      border-color: $logo-blue;
    }

    @media #{$md} {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }

    & .contentbox-image {
      border-right: 1px solid $logo-blue-lightest;
      flex: 0 0 auto;
      border-radius: 2px 0 0 2px;
      overflow: hidden;
      aspect-ratio: 1;
      min-width: 260px;
      max-width: 100%;
      width: unset;
      display: block;

      @media screen and (max-width: 650px) {
        min-width: 228px;
        border-radius: 2px 2px 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
      }
    }

    & .contentbox-badge {
      font-size: 10px;
      font-family: var(--medium);
      text-transform: uppercase;
      background-color: $logo-blue;
      color: $white;
      border-radius: 10rem;
      padding: 8px 12px;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      line-height: 1;
      flex-shrink: 0;
      position: absolute;
      top: -13px;
      right: 20px;
    }

    & .contentbox-title {
      font-size: $lead-font-size;
      font-family: var(--light);
      text-wrap: pretty;
      line-height: 1.2;
      flex-direction: column;
      gap: 4px;

      @media #{$xs} {
        font-size: $standard-font-size;
      }
    }

    & .contentbox-meta {
      display: flex;
      font-size: 12px;
      color: $gray-text-light;
      gap: 8px;
      font-family: var(--light);
      flex-wrap: wrap;
    }

    & .contentbox-meta__item {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    & .contentbox-description {
      color: $logo-blue;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-family: var(--light);
      background-color: $logo-blue;
      gap: 4px;
      justify-content: center;
      font: 400 12px var(--medium) !important;
      padding: 0px 15px;
      line-height: 18px;
      height: 40px;
      border-radius: 2px;
      text-align: center;
      color: white;
      text-transform: uppercase;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: $logo-blue-darkened;
      }

      &::after {
        content: '➞';
      }
    }

    ul.contentbox-list {
      margin: 0;
      padding: 0;
      list-style: none;
      color: $gray-text;
      font-size: 14px;

      & li {
        display: flex;
        align-items: center;
        gap: 4px;

        + li {
          margin-top: 12px;
        }

        @media #{$sm} {
          font-size: 14px;
        }
      }
    }

    & .contentbox-topic {
      flex: 1 1 auto;
      padding: 20px;
      background-color: $logo-blue-bg;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: space-between;

      @media #{$sm} {
        padding: 15px;
      }
    }
  }

  & .grid > .columns .contentbox--highlight {

    & > .contentbox-topic {
      padding: 15px;
      align-items: flex-start;

      & > .contentbox-description {
        font-size: 12px;
      }

      & > .contentbox-title {
        margin-bottom: 7px !important;
      }
    }
  }

  & .contentbox-title {
    margin-bottom: 0 !important;
  }

  & .contentbox--highlight {
    & .contentbox-badge {
      font-size: 10px;
      font-family: var(--medium);
      text-transform: uppercase;
      background-color: $logo-blue;
      color: $white;
      border-radius: 10rem;
      padding: 4px 8px;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      line-height: 1;
      position: absolute;
      top: -10px;
      transform: translateX(-2px);
    }

    & .contentbox-description {
      color: $logo-blue;
      font-family: var(--light) !important;
      text-decoration: none !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      &::after {
        content: '➞';
      }
    }
  }

  & .fading-banner {
    background-color: $logo-blue-bg;

    & .l-container {
      padding: 15px 0;
      gap: 15px;
    }

    & .fading-banner-headline {
      color: $logo-blue;
      font-family: var(--light);
      margin-bottom: 4px;
    }

    & .fading-banner-btn {
      display: none;
    }

    & .fading-banner__text {
      margin: 0;
      font-size: 14px;
      line-height: 1.2;

      @media #{$md} {
        display: none;
      }
    }

    & .contentbox-image {
      width: 65px;
      height: 65px;
      border-radius: 2px;
      overflow: hidden;
      background-color: #ffffff;
      position: relative;

      @media #{$md} {
        display: none;
      }

      & img {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
