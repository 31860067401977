.drawer {

  & > .drawer-content {
    clear: both;
    background-color: $gray-bg-dark;
    overflow: hidden;
    transition: height 1.5s linear, max-height 1.5s linear;
    max-height: 1500px;
  }

  &.closed {
    .drawer-content {
      max-height: 0px;
    }

    .drawer-toggle {
      background-color: $gray-bg-dark;

      & > .drawer-open {
        display: block;
      }

      & > .drawer-close {
        display: none;
      }
    }
  }

  .drawer-toggle {
    border-top: 1px solid $gray-border;
    padding: 20px;

    & > .drawer-open {
      display: none;
    }

    & a {
      cursor: pointer;
      color: $gray-text-light;
      text-decoration: none;
      font-size: 14px;

      .fa {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
}
