.milestone {
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  animation-delay: 2s;
  animation: bounceInRight 1s;
  background-color: $gray-bg;
  padding: 30px;

  @media #{$sm} {
    padding: 20px;
    flex-direction: column;
  }

  & > .milestone__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px 0 0;

    @media #{$sm} {
      margin: 0 0 20px 0;

      & > img {
        max-width: 100%;
      }
    }
  }

  & > .milestone__message {
    color: $gray-text-light;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 258px;

    @media #{$sm} {
      height: auto;
    }

    & > .milestone__headline {
      font-family: var(--regular);
      font-size: 24px;
      line-height: 34px;
    }

    & > .milestone__copy {
      font-family: var(--light);
      font-size: 20px;
      line-height: 34px;
    }
  }

}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)
  }

  75% {
    transform: translate3d(10px, 0, 0)
  }

  90% {
    transform: translate3d(-5px, 0, 0)
  }

  to {
    transform: none;
  }
}
