.alert {
  top: 0;
  text-align: center;
  color: #FFF;
  position: fixed;
  z-index: 10000;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: $red;

  &.success {
    background-color: $green;
  }

  &.notice {
    background-color: $logo-blue;

  }

  a {
    color: $white;
    text-decoration: underline;
    font-family: var(--medium);
  }

  form {
    margin: 0;
    display: inline-block;

    button[type="submit"] {
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      border: none;
      color: white;
      padding: 0;
      outline: none;
      font-family: inherit;
      font-size: 100%;
    }
  }
}
