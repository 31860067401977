.control-group {
  margin-bottom: 25px;
}

label.inline-fixed {
  width: 200px;
  display: inline-block;
  margin-bottom: 15px;
}

label.inline-flex {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 15px;
}

// SOCIAL AUTHENTICATION
// ----------

.social-authentication {
  & > form {
    margin-top: 0;
    margin-bottom: 0;

    // overwrite .box [type="submit"]
    & > [type="submit"] {
      float: initial;
    }
  }
}

// Custom range slider styles

input[type="range"] {
  appearance: none;
  cursor: pointer;
  width: 100%;
  height: 5px;
  background: $white;
  border-radius: 5px;
  background-image: linear-gradient($logo-blue, $logo-blue);
  background-size: 70% 100%;
  background-repeat: no-repeat;
  transition: transform .2s ease;
}

input[type="range"]:focus {
  outline: none;
  box-shadow: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: transparent;
  border-radius: 10px;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  margin-top: -5px;
  background-color: $logo-blue;
  border-radius: 30px;
  height: 15px;
  width: 15px;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: none;
  transform: scale(1.01);
}

input[type="range"]::-moz-range-track {
  background-color: transparent;
  border-radius: 10px;
  height: 5px;
}

input[type="range"]::-moz-range-thumb {
  background-color: $logo-blue;
  border: none;
  border-radius: 30px;
  height: 15px;
  width: 15px;
}

input[type="range"]:focus::-moz-range-thumb {
  outline: none;
  transform: scale(1.01);
}

.link-form {
  display: inline;
  margin: 0;

  & > button {
    position: relative;
  }
}

// Custom checkbox toggle

.toggle-switch {
  &,
  > form {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 auto;
  }

  .label {
    font-size: $tiny-font-size;
    color: $gray-text;
  }

  form {
    & button {
      cursor: pointer;
      text-indent: -9999rem;
      width: 40px;
      height: 22px;
      display: block;
      border-radius: 10rem;
      position: relative;
      border: 1px solid $gray-border;
      background-color: $white;
      margin: 0;
    }

    & input[type=checkbox] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    & button:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: $gray-border;
      border-radius: 10rem;
      transition: 0.3s;
    }

    & input:checked + button:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
      background: $logo-blue;
    }

    & button:active:after {
      width: 22px;
    }
  }
}
