.related-study-units {
  margin-bottom: 30px;

  & > .grid > .columns > .contentbox > .contentbox-topic {
    & > .contentbox-title {
      line-height: 16px;
      font-size: 13px;
      margin-bottom: 5px;
    }

    & > .contentbox-description {
      text-decoration: none;
      line-height: 16px;
    }
  }
}

.related-articles {
  margin-bottom: 30px;

  .contentbox > .contentbox-topic {
    & > .contentbox-title {
      line-height: 16px;
      font-size: 13px;
      margin-bottom: 0;
    }

    .fa {
      display: none;
    }

    & > .contentbox-description {
      display: none;
    }
  }
}

.atlas-gallery {
  margin-bottom: 50px;
}
