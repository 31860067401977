.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-family: var(--light);
  font-size: 60px;
  margin-right: 9px;
  padding-top: 4px;


  @media #{$md} {
    height: 70px;
    font-size: 42px;
    margin-right: 5px;
  }

  @media #{$xs} {
    height: 36px;
    font-size: 22px;
    margin-right: 5px;
  }

  &.counter__digit {
    width: 100px;
    background: linear-gradient(to bottom, #f7f7f7 0%, #f7f7f7 50%,
                                #eeeeee 50%, #eeeeee 50%, #eeeeee 100%);
    color: $logo-blue;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .20);

    @media #{$md} {
      width: 70px;
    }

    @media #{$xs} {
      width: 36px;
    }
  }
}

