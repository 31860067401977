.invoice {
  margin: 30px auto;
  max-width:800px;
  padding:30px;
  border:1px solid #eee;
  color: $gray-text;

  & table {
    width: 100%;

    & td {
      padding:5px;
      vertical-align:top;

      &:nth-child(2) {
        text-align:right;
      }
    }

    & tr.heading td {
      background: #eee;
      font-family: var(--medium);
    }

    & tr.item td {
      border-top:1px solid #eee;
    }
  }

  & .kenhub__data {
    margin-top: 50px;
    text-align: center;
  }
}
.invoice table tr.information table td {
  padding-bottom: 40px;
}

.invoice table tr.total td:nth-child(2){
  border-top: 2px solid #eee;
  font-family: var(--medium);
}
