// RECOMMENDATIONS
// ----------

.recommendations {
  margin-bottom: 58px;

  &__testimonials {
    @media #{$md} {
      margin-top: 50px;
    }
  }
  & > h2 {
    padding-top: 80px;
    width: 100%;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    @media #{$md} {
      width: 100%;
      margin-bottom: 50px;
    }
    @media #{$lg} {
      margin-bottom: 70px;
    }
  }
  @media #{$md} {
    &.recommendations--landing {
      margin-bottom: 0;
    }
  }
}

.recommendation {

  .recommendation-quote {
    font-family: var(--light);
    color: $gray-text;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 40px;
    margin-top: 0;
  }
  .recommendation-name {
    font-family: var(--light);
    color: $logo-blue;
    font-size: $small-font-size;
    line-height: 21px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .recommendation-info {
    font-family: var(--light);
    color: $gray-text;
    font-size: $small-font-size;
    line-height: 21px;
    margin-bottom: 0;
    margin-top: 0;
  }

  &.recommendation--landing {
    text-align: center;

    & > img {
      height: 125px;
      width: 125px;
      margin-bottom: 30px;
      border-radius: 62.5px;
    }

    & > .recommendation-quote {
      line-height: 28px;
      margin-bottom: 50px;
    }

    & > .recommendation-name {
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      font-family: var(--regular);
    }

    & > .recommendation-info {
      font-size: 16px;
      line-height: 24px;
      min-height: 60px
    }
  }
}
