.zoom-icon, .image-menu-item  {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 5px;
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 3px;
  background-color: rgba($white, .9);
  cursor: pointer;
  z-index: 10;
  color: $gray-text-lightest;

  @media #{$xs} {
    right: 0;
    top: 0;
  }

  & > .fa {
    font-size: $standard-font-size;
    color: inherit;
  }

  &:hover,
  &:focus {
    color: $logo-blue;
  }

  &.image-menu-item--flag {
    top: 60px;

    @media #{$xs} {
      display: none;
    }
  }
}
