.embedded-video {
  border: 1px solid $gray-border;
  background-color: $gray-bg-dark;
  border-radius: 2px;
  display: block;
  width: 100%;
  clear: both;
  cursor: auto;
  position: relative;
  overflow: hidden;

  & > .embedded-video--video {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    float: none;
    padding-bottom: 56.16%;
    background-color: $white;

    & > picture > img {
      cursor: pointer;
    }

    & > picture > img, & > iframe {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > .embedded-video--options {
    padding: 20px;
    overflow: hidden;
    animation: expandHeight 2s;
    position: relative;
    background-color: $gray-bg-dark;

    @media #{$sm} {
      padding: 20px;
      width: 100%;
    }

    & > .video-timestamps-headline {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 10px;
      color: $gray-text;
      margin-top: 20px;
      font-family: var(--regular);
    }

    & > .embedded-video__tabs {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 10px;

      & > a {
        color: $logo-blue;
        text-decoration: none;

        &.active {
          color: $gray-text;
          cursor: initial;
        }
      }
    }

    & > .embedded-video__transcript {
      max-height: 400px;
      overflow: auto;
      color: $gray-text;
      background-color: $white;
      line-height: 20px;
      font-size: 14px;
      font-family: var(--regular);
      border: 1px solid $gray-border;
      border-radius: 2px;
      padding: 20px;
      margin-top: 30px;

      & p {
        font-size: 14px;
        color: $gray-text;
        line-height: 21px;
        margin-bottom: 20px;
        font-family: var(--regular);
      }
    }
  }

  & > .embedded-video--content {
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 30px;

    @media #{$sm} {
      padding: 20px;
      width: 100%;
      float: none;
    }

    & > .embedded-video--title {
      font-size: 14px;
      font-family: var(--regular);
      color: $logo-blue;
      font-weight: normal;
      overflow: visible;
      white-space: normal;
      text-overflow: inherit;
      line-height: 19px;
      margin-bottom: 5px;

      & a {
        color: $logo-blue;
        text-decoration: none;
      }
    }

    & > .embedded-video--description {
      margin-top: 0;
      overflow: visible;
      white-space: normal;
      text-overflow: inherit;
      font-family: var(--light);
      color: $gray-text;
      font-size: $tiny-font-size;
      line-height: 20px;
    }
  }

  .milestone {
    position: relative;
    margin-bottom: 0;
  }

  .embedded-video__feedback {
    float: right;

    & .fa-flag {
      margin-top: 3px;
      color: $gray-text-light;
    }
  }

  .close {
    top: 10px;
    right: 20px;
    position: absolute;
    width: 19px;
    height: 18px;
    cursor: pointer;
    z-index: 1001;
    padding: 15px;
    background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center
  }
}
