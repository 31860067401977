.modal.quiz_type_selector {
  padding: 0;

  & > .modal-header {
    margin: 30px 30px 0;

    @media #{$sm} {
      margin: 30px 15px 0;
    }
  }

  & > .modal-body {
    padding: 0 30px;

    @media #{$sm} {
      padding: 0 15px;
    }

    &:last-child {
      padding: 0 30px 15px;
    }

    & > .banner {
      margin: 15px 0;
    }

    & .quiz-type {
      border-bottom: 1px solid $gray-bg-dark;

      &:last-of-type {
        border-bottom: none;
      }

      & > .error {
        color: $red;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 15px;
      }

      & > .quiz-type__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        cursor: pointer;

        &:hover {
          & .quiz-type__title {
            & > span {
              color: $logo-blue;
            }

            & .circle {
              background-color: $gray-border;
            }
          }
        }
      }

      & .quiz-type__title {
        margin-top: 0;
        font-size: 14px;

        & .estimated-time {
          color: $gray-text-lightest;
          font-size: 12px;
          margin-top: 4px;

          .fa {
            margin-right: 2px;
          }
        }

        & > span {
          color: $gray-text;
          margin-right: 10px;

          @media #{$xs} {
            flex-basis: 70%;
          }
        }

        & .circle {
          background-color: $gray-bg;
          color: $gray-text;
          margin: 0 0 0 5px;
          font-size: 12px;
          vertical-align: middle;
        }
      }

      & .btn {
        vertical-align: middle;
      }

      & .btn-primary {
        background-color: $white;
        border: 1px solid $logo-blue;
        color: $logo-blue;
        margin-left: 5px;
        margin-top: 0;

        & > .ladda-spinner > div > div > div {
          background: $logo-blue !important;
        }

        &:hover {
          background-color: $logo-blue;
          color: $white;

          & > .ladda-spinner > div > div > div {
            background: $white !important;
          }
        }
      }

      & .btn-red {
        background-color: $white;
        border: 1px solid $red;
        color: $red;

        &:hover {
          background-color: $red;
          color: $white;
        }
      }

      & ul.quiz-type__options {
        position: relative;
        background-color: $gray-bg;
        border: 1px solid $gray-border;
        padding: 15px;
        margin: 0 !important;

        &::before,
        &::after {
          content: "";
          position: absolute;
          bottom: 100%;
          width: 0;
          height: 0;
          border-style: solid;
        }

        &::before {
          border-width: 0 7px 6px 7px;
          border-color: transparent transparent $gray-border transparent;
          right: 78px;
        }

        &::after {
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent $gray-bg transparent;
          right: 80px;
        }

        & > li {
          list-style: none;
          font-size: 14px;
          color: $gray-text-light;
          margin: 0 0 10px 10px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          & > input {
            display: inline-block;
            margin-right: 7px;
            cursor: pointer;
          }
        }
      }

      .question-count__label {
        font-size: 12px;
      }

      .question-count__ranges {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 10px;
        color: $gray-text-light;
        padding-top: 5px;
      }

      & .btn-icon {
        font-size: $standard-font-size;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        font-size: $small-font-size;
      }

      & .quiz-type__more-info {
        display: none;
        min-height: 50px;
        position: relative;
        margin-bottom: 15px;

        & > .spinner {
          min-height: 100px;
          width: 100%;
          border: 1px solid $gray-border;

          &:before {
            z-index: -1;
          }

          & > img {
            display: block;
            width: 100%;
          }
        }

        & > p {
          color: $gray-text-light;
          margin-top: 15px;
          max-width: 385px;
        }
      }

      &.active {
        & .quiz-type__title {
          & > span {
            color: $logo-blue;
          }

          & .circle {
            background-color: $gray-border;
          }
        }

        & .btn-primary {
          background-color: $logo-blue;
          color: $white;

          & > .ladda-spinner > div > div > div {
            background: $white !important;
          }

          &:hover {
            background-color: $logo-blue-darkened;
          }
        }

        & .btn-red {
          background-color: $red;
          color: $white;

          &:hover {
            background-color: $red-darkened;
          }
        }

        & .quiz-type__more-info {
          display: block;
        }
      }
    }
  }

  & > .modal-footer {
    text-align: center;
    font-family: var(--regular);
    font-size: 12px;
    line-height: 17px;
    background-color: $red;
    color: $white;
    border-top: none;
    padding: 20px;

    & > a {
      color: #fff;
      font-family: var(--medium);
      text-decoration: underline;
    }
  }
}
