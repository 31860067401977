
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter:none !important;
    -ms-filter: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  /*
   * required for all parent elements containing children
   * that define page breaks, otherwise it won't work
   */

  .l-container,
  .l-main {
    float: none !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  footer,
  .embedded-video,
  .l-topbar,
  .l-hero,
  .middlebar,
  .filterbar,
  .sidebar,
  .well--toc,
  .related-training-unit,
  .chapter,
  .related-content-list,
  #panel > .content > .pull-right,
  #panel > .content > p.small,
  .contentbox.contentbox--small,
  .fading-banner-headlines,
  .fading-banner-ctas {
    display: none;
  }

  .span9 {
    width: 100% !important;
  }

  .article-meta-content {
    display: block;
    border: 1px solid #ddd;
    margin-top: 10px;

    &, & p {
      font-size: 10px;
    }
  }

  h1,
  h2,
  h3 {
    font-weight: bold;

    &:first-child {
      counter-reset: page;
    }
  }

  article {
    h1 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 40px;
      margin-top: 10px;
    }

    h2 {
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 40px;
      clear: both;
    }

    h3 {
      font-size: 12px;
      margin-bottom: 3px;
      margin-top: 3px;
    }

    img {
      border: none;
      max-width: 250px !important;
      display: block !important;
      float: right !important;
      padding: 15px !important;
      padding-right: 0 !important;
      clear: right;
    }

    &:before {
      background-image: url("~images/logo.webp") !important;
      background-repeat: no-repeat !important;
      background-position: right bottom !important;
    }
  }

  p, ul li {
    font-size: 12px !important;
    line-height: 18px !important;
    word-spacing: normal;
  }

  ul li {
    margin-bottom: 0;
  }

  h3 + p {
    float: none;
  }

  .well.well--infobox {
    border: 1px solid #DDD;
    margin-top: 30px;

    & h2:before {
      display: none;
    }

    & h3 {
      font-family: var(--light);
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
