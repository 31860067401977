.filterbar {
  border-top: 1px solid $gray-border;
  border-bottom: 1px solid $gray-border;
  background-color: #fff;

  .l-container {
    min-height: 60px;
    margin-bottom: 0px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @media #{$md} {
      padding: 10px 15px;
    }
  }
}

.content-tags-hidden {
  display: none !important;
}

.locale-tags-hidden {
  display: none !important;
}
