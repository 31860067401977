.seo-footer {
  border-top: 1px solid $gray-border;
  padding-top: 50px;
  clear: both;

  & p {
    text-align: justify;
    font-family: var(--light);
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 0;
    
    & + p {
      @media #{$md} {
        margin-top: 50px;
      }
    }
  }

  & a {
    text-decoration: underline;
    color: $gray-text;
  }
}

.image-loading-error {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red;
  font-size: 24px;
}
