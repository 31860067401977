figure.overview-image {
  position: relative;
  margin: 0;
  border: 1px solid $gray-border;
  min-height: 200px;
  margin-bottom: 0;
  background-color: $white;

  & > img {
    width: 100%;
    display: block;
    border: none;
    margin: 0;
  }

  & .overview-image__title {
    font-size: 13px;
    font-family: var(--regular);
  }

  & figcaption {
    background-color: $gray-bg;
    padding: 20px;
    border-top: 1px solid $gray-border;
    font-family: var(--light);
    line-height: 20px;
    font-size: 12px;
    text-align: left;
    color: $gray-text;
  }
}

.overview-images-container {
  @media #{$md-lg-only} {
    &.outset-left:not(.extended) {
      float: right;
      margin-left: 17px;
      margin-bottom: 20px;
      width: 280px;
    }

    &.outset-right:not(.extended) {
      float: left;
      margin-right: 17px;
      margin-bottom: 20px;
      width: 280px;
    }
  }
}
