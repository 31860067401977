.topicbox-small-container {
  margin-bottom: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 300px);

  display: -ms-grid;
  -ms-grid-columns: 300px 20px 300px 20px 300px;
  -ms-grid-rows: auto 20px auto 20px auto;

  & > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  & > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  & > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }
  & > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  & > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  & > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }
  & > *:nth-child(7) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  & > *:nth-child(8) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  & > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
  }

  @media #{$md} {
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-columns: 1fr 20px 1fr;
    -ms-grid-rows: auto 20px auto 20px auto 20px auto 20px auto;

    & > *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    & > *:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    & > *:nth-child(3) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
    & > *:nth-child(4) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }
    & > *:nth-child(5) {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }
    & > *:nth-child(6) {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }
    & > *:nth-child(7) {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
    }
    & > *:nth-child(8) {
      -ms-grid-row: 7;
      -ms-grid-column: 3;
    }
    & > *:nth-child(9) {
      -ms-grid-row: 9;
      -ms-grid-column: 1;
    }
  }

  @media #{$sm} {
    display: block;

    & > * {
      margin-bottom: 20px;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.topicbox-container {
  margin-bottom: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  display: -ms-grid;
  -ms-grid-columns: 1fr 20px 1fr;
  -ms-grid-rows: auto 20px auto 20px auto 20px auto 20px auto 20px auto 20px auto;

  & > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  & > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  & > *:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  & > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  & > *:nth-child(5) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  & > *:nth-child(6) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  & > *:nth-child(7) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
  }
  & > *:nth-child(8) {
    -ms-grid-row: 7;
    -ms-grid-column: 3;
  }
  & > *:nth-child(9) {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
  }
  & > *:nth-child(10) {
    -ms-grid-row: 9;
    -ms-grid-column: 3;
  }

  @media #{$md} {
    display: block;

    & > * {
      margin-bottom: 20px;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.topicboxes {
  margin-bottom: 35px;
  text-align: left;
  overflow: hidden;

  @media #{$md} {
    margin-bottom: 0;
  }

  & p {
    font-family: var(--regular);
    color: $gray-text-light;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.topicboxes__runner {
  display: flex;
  overflow: visible;
}

.topicbox {
  height: 160px;
  border: 1px solid $gray-border;
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media #{$sm} {
    height: auto;
  }

  .topicbox__image {
    width: 158px;
    position: relative;

    img {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: absolute;
    }

    @media #{$sm} {
      display: none;
    }
  }

  .topicbox__copy {
    flex: 1;
    background-color: $gray-bg-dark;
    border-left: 1px solid #ececec;
    padding: 26px 30px;
    // https://css-tricks.com/flexbox-truncated-text/
    min-width: 0;


    @media #{$xs} {
      padding: 20px 20px;
    }

    & > .topicbox__title {
      margin-bottom: 19px;
      font-size: 18px;
      line-height: 18px;
      margin-top: 0;
      font-family: var(--regular);
      color: $logo-blue;
      font-weight: normal;
    }

    & > .topicbox__description {
      overflow: hidden;
      font-family: var(--light);
      color: $gray-text;
      font-size: 13px;
      line-height: 18px;
      margin-top: 0;
    }
  }

  &--without-image {
    min-height: auto;
    min-width: auto;

    & .topicbox__copy {
      height: auto;
      width: 100%;
      float: none;
    }
  }
}

.topicbox-small {
  height: 100px;
  border: 1px solid $gray-border;
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .topicbox__image {
    width: 98px;
    position: relative;

    img {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: absolute;
    }
  }

  .topicbox__copy {
    flex: 1;
    background-color: $gray-bg-dark;
    border-left: 1px solid #ececec;
    padding: 16px 20px;
    // https://css-tricks.com/flexbox-truncated-text/
    min-width: 0;

    & > .topicbox__header {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 18px;
      margin-top: 0;
      font-family: var(--regular);
      color: $logo-blue;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }

    & > .topicbox__description {
      overflow: hidden;
      font-family: var(--regular);
      color: $gray-text-light;
      font-size: 12px;
      line-height: 15px;
      margin-top: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow-wrap: break-word;
    }
  }
}

.topicbox__lecture_state {
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  font-family: var(--regular);
  font-weight: normal;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 5px;

  .badge__container.completed .badge {
    color: $gray-text;
    background-color: $gold;
  }

  .badge__container.incomplete .badge {
    color: $gray-text;
    background-color: white;
  }

  .tooltip.lecture-state-tooltip {
    display: none;
    width: 168px;
    
    &.show {
      display: block;
    }

    &.tooltip-text {
      position: relative;
    }
  }
}
