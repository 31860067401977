.embedded-quiz-summary {
  padding: 20px 30px;

  @media #{$sm} {
    padding: 15px 15px 1px;
  }

  & .title {
    font-size: $tiny-font-size;
    margin-bottom: 4px;
  }

  & .meta {
    font-size: 11px;
    font-family: var(--light);
    color: $gray-text-light;
    margin-bottom: 20px;
  }

  & .badge.progress {
    display: none;
    vertical-align: middle;

    & .fa {
      font-size: $tiny-font-size;
    }

    &.positive,
    &.negative {
      display: inline-flex;
      gap: 4px;
    }

    &.positive {
      background-color: $green-bg-light;
      color: $green;
    }

    &.negative {
      background-color: $red-bg-light;
      color: $red;

      & svg.fa {
        transform: scaleY(-1);
      }
    }
  }

  .results-container {
    border-radius: 3px;
    position: relative;
    text-align: center;
    padding: 30px 40px;

    @media #{$sm} {
      padding: 15px;
      margin: 0 -15px;
    }

    &.passed {
      background: $dark-bg-blue url("~images/quiz/summary-bg.svg") no-repeat;
      background-size: contain;
      background-position: top;
      color: $white;

      & .title {
        color: $green;
      }
    }

    &.failed {
      background-color: $white;

      & .title,
      & .stats .figure {
        color: $logo-blue;
      }
    }

    & .title {
      font-size: 32px;
      line-height: 1.2;
      font-family: var(--light);

      @media #{$sm} {
        font-size: $lead-font-size;
      }
    }

    & .description {
      line-height: 1.5;
      font-family: var(--light);
      margin-bottom: 20px;
      font-size: $tiny-font-size;

      & a {
        text-decoration: underline;
      }
    }

    & .counters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      padding: 0 40px;

      & > .counter {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font: $medium-font-weight $mini-font-size var(--medium);
        border-radius: 2px;
        text-align: center;
        white-space: nowrap;
        color: white;
        text-transform: uppercase;
        line-height: 15px;
        height: 32px;
        padding: 8px 12px;
        margin: 0;
        gap: 4px;
        flex: 1;

        &.correct {
          color: $green-darkened;
          background-color: $green-bg-light;
        }

        &.incorrect {
          color: $red;
          background-color: $red-bg-light;
        }

        &.skipped {
          color: $gray-text-light;
          background-color: $gray-bg-light;
        }

        @media #{$sm} {
          flex: 1 0 100%;
        }
      }

      & .btn-light {
        padding: 8px 12px;
        font-size: $mini-font-size;
        flex: 1 0 100%;
      }

      @media #{$sm} {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      @media #{$xs} {
        padding: 0;
      }
    }
  }

  & .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;

    & .stat {
      text-align: center;
      flex: 1 0 auto;
    }

    & .figure {
      font-size: 40px;
      font-family: var(--regular);

      @media #{$sm} {
        font-size: $lead-font-size;
      }
    }

    & .legend {
      font-family: var(--light);
      font-size: 13px;

      @media #{$sm} {
        font-size: 12px;
      }
    }
  }

  & .last-quiz {
    margin-top: 16px;
    font-size: $tiny-font-size;
    text-align: center;

    @media #{$xs} {
      font-size: $mini-font-size;
    }

    & .timestamp {
      font-family: var(--light);

      &::before {
        content: ' · ';
      }
    }

    & .actions {
      margin-left: auto;

      & .btn-text-link {
        text-transform: uppercase;
        font-size: 12px;
        font-family: var(--medium);

        & .short {
          display: none;
        }

        @media #{$xs} {
          font-size: 11px;

          & .long {
            display: none;
          }

          & .short {
            display: inline;
          }
        }
      }
    }
  }

  & .learnable-progress-box {
    background-color: $white;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    border-radius: 3px;

    & .title {
      flex: 1;
      font-size: 13px;
      margin-bottom: 0;
    }

    & .progress-donut {
      height: 30px;
      width: 30px;
      position: relative;
      overflow: visible;
    }
  }

  .progress-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8px;

    @media #{$sm} {
      display: block;
      background-color: $white;
      border-radius: 3px;
      padding: 20px 8px;
      margin-bottom: 20px;
    }
  }

  .mastery-level-container,
  .last-session-stats-container {
    padding: 20px;
    text-align: center;
    background-color: $white;
    border-radius: 3px;

    @media #{$sm} {
      border-radius: 0;
      background-color: unset;
      padding: 0;
    }

    & .show-detailed.btn-link.btn {
      text-transform: none;
      text-decoration: underline;
      margin: 18px 0 3px 0;
      height: 18px;
      font: 400 14px var(--regular);
      gap: 5px;
      color: $gray-text-light;

      @media #{$sm} {
        margin-top: 26px;
        font-size: 12px;
      }
    }
  }

  .mastery-level-container {
    position: relative;
    width: 100%;

    .fa-graduation-cap-circle {
      display: inline-block;
      font-size: 110px;
      line-height: 1;
      @include gold-shimmer(10s);
      max-width: 200px;
    }

    & .title {
      color: $logo-blue;
      margin-bottom: 18px;
    }

    & .progress-donut-container {
      position: relative;
    }

    & .progress-donut {
      position: relative;
      height: 110px;
      z-index: 1;
    }

    & .fa-graduation-cap {
      font-size: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $gray-bg-dark;
    }
  }

  .last-session-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .last-session-text {
      font-size: $tiny-font-size;
      font-family: var(--regular);
      color: $gray-text;
      margin-bottom: 18px;

      @media #{$sm} {
        margin: 18px 0;
      }
    }

    & .percentage-boxes {
      display: flex;
      width: 100%;
    }

    & .percentage-box {
      flex: 1 0 50%;

      & .figure {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;

        & > .fa {
          font-size: $lead-font-size;
          margin-right: 4px;
        }

        & > .fa-star {
          margin-top: -4px;
        }
      }

      & .percentage {
        font-family: var(--light);
      }

      & .label {
        font-family: var(--light);
        font-size: 12px;
        min-height: 22px;

        & .badge {
          margin-right: 3px;
        }
      }

      & .cta-text {
        margin-top: 18px;
        font-size: 13px;
        line-height: 1.5;
        display: flex;
        flex-direction: column;

        .btn {
          height: auto;
          font-size: 12px;
          margin: 0;
        }
      }
    }

    & .percentage-box--blue .figure .fa {
      color: $logo-blue;
    }

    & .percentage-box--green {
      & .figure .fa {
        color: $green;
      }

      & .label {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .percentage-box--red .figure .fa {
      color: $red;
      transform: scaleY(-1);
    }

    & .percentage-box--gold .figure {
      color: $gold;
    }

    & .badge--red {
      margin-top: 18px;
      font-size: 12px;
      font-family: var(--medium);

      & .fa {
        font-size: 13px;
        margin: -2px 4px 0 0;
      }
    }
  }

  .detailed-learning-progress-title {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    gap: 8px;

    @media #{$sm} {
      flex-direction: column;
      justify-content: center;

    }

    & .title {
      margin-right: auto;
      font-family: var(--regular);
      color: $gray-text;
      margin-bottom: 0;

      @media #{$sm} {
        margin: 0;
      }
    }

    & .key {
      font-size: 12px;
      font-family: var(--light);
      display: flex;
      align-items: center;
      gap: 8px;

      & .item {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 4px;
      }

      & .dot {
        display: inline-block;
        border-radius: 100%;
        width: 8px;
        height: 8px;
      }

      & .icon--light-blue {
        background-color: $logo-blue-light;
      }

      & .icon--logo-blue {
        background-color: $logo-blue;
      }

      & .icon--gold {
        color: $gold;
      }
    }
  }

  .learnables-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @media #{$sm} {
      grid-template-columns: 1fr;
      padding-bottom: 15px;
    }

    .more-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: $white;
      appearance: none;
      border: 0;
      color: $logo-blue;
      text-transform: uppercase;
      font-size: 12px;
      font-family: var(--medium);
      min-height: 45px;
      cursor: pointer;

      & .fa {
        margin-left: 4px;
      }
    }
  }

  .next-recommended-study-session {
    background-color: $white;
    border-radius: 3px;
    display: flex;
    padding: 20px 30px 20px 30px;
    align-items: center;
    margin-bottom: 8px;

    @media #{$sm} {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
    }

    & .content {
      margin-right: auto;
      padding-right: 20px;
      font-size: 13px;

      @media #{$sm} {
        margin: 0 0 12px;
        padding: 0;
      }
    }

    & .time {
      font-size: 32px;
      margin-top: 4px;

      @media #{$sm} {
        margin-top: 8px;
      }

      & .fa {
        font-size: 24px;
      }
    }

    &.now {
      border: 1px solid $logo-blue;

      & .time {
        color: $logo-blue;
      }
    }

    & .btn-link {
      font-size: 12px;
    }

    & .btn-primary {
      font-size: 11px;
    }
  }
}

.modal.study-summary {
  max-width: 700px;
  margin-left: -350px;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  @media #{$md} {
    max-width: 100%;
    margin: 0 auto;
    max-height: unset;
    top: 0 !important;
    border-radius: 0;
    height: 100%;
    padding-bottom: 15px;
  }

  & .modal-title {
    font-family: var(--light);
    font-size: $standard-font-size;
    margin-bottom: 12px;
    line-height: 1.2;
    display: flex;
    align-items: center;
  }

  & .modal-header {
    margin-bottom: 14px;
    border-bottom: 0;
    padding-bottom: 0;

    .close {
      width: 19px;
      height: 18px;
      margin: 0 0 0 auto;
      color: #c3c1c2;
      background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center;
      margin-top: 0;
      float: none;
    }
  }

  & .modal-body {
    overflow-y: auto;
    padding-bottom: 30px;
    height: 100%;
  }

  & .detailed-learning-progress-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    @media #{$xs} {
      grid-template-columns: 1fr;
    }
  }

  & .learnable-progress-box {
    border-radius: 4px;
    border: 1px solid $gray-border;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    min-width: 0;
    color: $gray-text;

    & .title {
      font-size: 13px;
      font-family: var(--regular);
    }
  }

  & .learnable-items-status {
    font-size: 11px;
    display: flex;
    gap: 6px;

    & .item {
      font-family: var(--light);
      display: flex;
      align-items: center;
    }
  }

  & .badge {
    gap: 3px;
    margin-left: auto;
    color: $gray-text-light;
    background-color: $gray-bg;

    &.positive {
      color: $green-darkened;
      background-color: $green-bg-light;
    }

    &.negative {
      color: $red-darkened;
      background-color: $red-bg-light;

      & svg.fa {
        transform: scaleY(-1);
      }
    }
  }


  & .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 3px;

    &.icon--light-blue {
      background-color: $logo-blue-light;
    }

    &.icon--logo-blue {
      background-color: $logo-blue;
    }
  }

  & .icon--gold {
    color: $gold;
    margin-right: 3px;
  }

  & .progress-donut {
    height: 30px;
    width: 30px;
    position: relative;
    overflow: visible;
  }

  & .key {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  & .info-line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    font-family: var(--light);
    color: $gray-text;

    @media #{$xs} {
      justify-content: center;
      text-align: center;
      gap: 8px;
    }

    & .overall-change {
      margin-right: auto;

      @media #{$xs} {
        margin-right: 0;
      }

      & .badge {
        margin-right: 4px;
      }
    }
  }
}

.modal.exam-summary {
  max-width: 700px;
  margin-left: -350px;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
    min-height: 1px;
  }

  @media #{$md} {
    max-width: 100%;
    margin: 0 auto;
    max-height: unset;
    top: 0 !important;
    border-radius: 0;
    height: 100%;
  }

  & .exam-quiz-summary {
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  & .modal-title {
    font-family: var(--light);
    font-size: $standard-font-size;
    margin-bottom: 12px;
    line-height: 1.2;
    display: flex;
    align-items: center;

    & .fa-angle-left {
      font-size: 20px;
    }
  }

  & .modal-header {
    margin-bottom: 12px;
    border-bottom: 0;
    padding-bottom: 0;

    .close {
      cursor: pointer;
      width: 19px;
      height: 18px;
      margin: 0 0 0 auto;
      color: #c3c1c2;
      background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center;
      margin-top: 0;
      float: none;
    }
  }

  & .modal-body {
    overflow-y: auto;
    padding-bottom: 30px;
  }

  & .questions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    @media #{$sm} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xs} {
      grid-template-columns: 1fr;
    }

    & .question {
      border: 1px solid $gray-border;
      padding: 12px;
      border-radius: 3px;
      font-size: 12px;
      color: $gray-text;
      position: relative;
      min-width: 0;
      display: flex;
      min-height: 88px;
      cursor: pointer;

      & .content {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.success {
        border: 1px solid $green;
        background-color: $green-bg-light;
      }

      &.failed {
        border: 1px solid $red;
        background-color: $red-bg-light;
      }

      &.skipped, &.null {
        opacity: .7;
      }

      & .status-icon {
        position: absolute;
        top: 6px;
        right: 6px;
      }

      & .type {
        font-family: var(--medium);
        display: inline-block;
        margin-right: 4px;
      }

      & .circle {
        margin: 0;
        font-size: 16px;
        padding: 3px;
      }
    }
  }

  & .question-inner {
    display: flex;
    gap: 12px;
    align-items: center;
    min-width: 0;
  }

  & .question-content {
    min-width: 0;
  }

  & .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;

    & .key {
      font-size: 10px;
      text-transform: uppercase;
      font-family: var(--light);
    }

    & .badge {
      border-width: 1px;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      appearance: none;
      border: 1px solid transparent;
      background-color: transparent;
      cursor: pointer;

      span {
        display: inline-block;
      }
    }

    & .badge-all {
      border-color: $logo-blue;
      color: $logo-blue;

      &.active {
        background-color: $logo-blue;
        color: $white;
      }
    }

    & .badge-success {
      border-color: $green;
      color: $green;

      &.active {
        background-color: $green;
        color: $white;
      }
    }

    & .badge-failed {
      border-color: $red;
      color: $red;

      &.active {
        background-color: $red;
        color: $white;
      }
    }

    & .badge-skipped {
      border-color: $gray-text-light;
      color: $gray-text-light;

      &.active {
        background-color: $gray-text-light;
        color: $white;
      }
    }
  }

  & .footer {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background-color: $white;
    padding: 15px 30px;
    margin: 0 -30px 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, .15);

    & .btn {
      font-size: 12px;
      height: 36px;
      margin: 0;
    }
  }

  & .exercise-question {
    line-height: 1.4;
    color: $gray-text;
    margin-bottom: 14px;
    font-family: var(--regular);

    &.question {
      font-size: $small-font-size;
    }
  }

  & .image img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border: 1px solid $gray-border;
    border-radius: 3px;
  }

  & .explanation-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  & .explanation {
    margin-top: 18px;

    & .title {
      font-family: var(--regular);
      font-size: $small-font-size;
      margin-bottom: 4px;
    }

    & .content {
      font-size: $small-font-size;
      margin-bottom: 0;

      & p {
        margin-bottom: 18px;
        font-size: $tiny-font-size;
      }

      & .image-gallery {
        margin: 0 15px 18px 15px;
      }
    }
  }

  & .answer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & .answer-item {
    display: flex;
    gap: 6px;
    border-radius: 3px;
    color: $gray-text;
    padding: 12px;
    line-height: 1.4;
    font-size: $tiny-font-size;

    & .fa {
      font-size: 20px;
      padding: 4px;
      align-self: flex-start;
    }

    &.failed {
      background-color: $red-bg-light;
      border: 1px dashed $red;

      & .title {
        color: $red-darkened;
      }
    }

    &.skipped {
      background-color: $gray-bg-light;
      border: 1px dashed $gray-border-dark;
    }

    &.correct {
      background-color: $green-bg-light;
      border: 1px solid $green;

      & .title {
        color: $green-darkened;
      }

      .divider {
        border-bottom: 1px solid $green;
        margin: 14px 0;
      }
    }

    & .title {
      font-family: var(--medium);
    }

    & ul {
      margin: 8px 0 0 20px;
    }

    & .no-bullets ul {
      list-style-type: none;
    }
  }
}

.click-to-return {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

// learnable-progress-box tooltip

.learnable-tooltip {
  line-height: 14px;
  max-width: 300px;
  width: auto;
  font-size: 11px;
  font-family: var(--light);
  text-align: left;

  & .bold {
    font-family: var(--medium);
  }
}

.tooltip-title {
  margin-bottom: 4px;
}

.tooltip-item {
  display: flex;
  text-transform: lowercase;
}
