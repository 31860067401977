html.trainer {
  height: 100%;

  body {
    height: 100%;
  }

  #panel {
    height: 100%;

    #trainer_container {
      height: 100%;
      display: flex;
    }
  }
}
