.sample-quiz {
  background-color: $gray-bg-dark;
  display: flex;
  flex-direction: column;
  font-family: var(--regular);

  & > div {
    display: flex;
    flex-direction: column;
  }

  .topbar {
    background-color: $dark-bg-blue;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex: 0 0 auto;
    padding: 15px 20px;
    font-size: $tiny-font-size;
  
    @media #{$sm} {
      padding: 15px;
    }
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .content {
    position: relative;
    overflow: hidden;
    min-height: 371px;

    @media #{$sm} {
      min-height: 506px;
    }

    @media #{$xs} {
      min-height: 406px;
    }

    & > div {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;

      &.slide-enter {
        left: 100%;
      }

      &.slide-enter-active {
        transition: .6s ease left;
        left: 0%;
      }

      &.slide-exit {
        left: 0%;
      }

      &.slide-exit-active {
        transition: .6s ease left;
        left: -100%;
      }
    }
  }
  
  .exercise {
    display: flex;
    flex: 1 0 auto;
    padding: 20px;
    border: 1px solid $gray-border;
    border-top: 0;

    @media #{$sm} {
      flex-direction: column;
      padding: 15px;
    }
  }
  
  .question {
    color: $white;
  }
  
  .question-counter {
    margin: -5px 0 -5px 15px;
    color: $white;
    line-height: 1;
    padding: 5px 10px;
    background: $darker-bg-blue;
    border-radius: 30px;
    flex: 1 0 auto;
  }
  
  .image,
  .options {
    flex: 0 0 50%;
  
    @media #{$sm} {
      flex: 0 0 auto;
    }
  }
  
  .options {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: $tiny-font-size;
  
    @media #{$sm} {
      padding-left: 0;
    }
  }
  
  .option {
    display: flex;
    padding: 8px;
    line-height: 1.2;
    align-items: center;
    flex: 1 0 75px;
    border: 1px solid transparent;
    background-color: $white;
    cursor: pointer;
    transition: border-color .5s ease, color .25s ease;
  
    &:hover,
    &:focus {
      color: $logo-blue;
    }
  
    & + .option {
      margin-top: 10px;
  
      @media #{$sm} {
        margin-top: 5px;
      }
    }
  
    &.correct {
      color: $green;
      border-color: $green;
  
      &:hover,
      &:focus {
        color: $green;
      }
    }
  
    &.incorrect {
      color: $red;
      border-color: $red;
  
      &:hover,
      &:focus {
        color: $red;
      }
    }

    @media #{$sm} {
      flex-basis: 50px;
      max-height: 50px;
    }
  }
  
  .image {
    position: relative;
    height: 330px;
    background-color: $white;
    aspect-ratio: 1;

    img {
      display: block;
      margin: 0 auto;
      height: 100%;
      object-fit: contain;
    }

    @media #{$sm} {
      height: 250px;
      margin-bottom: 10px;
    }

    @media #{$xs} {
      height: 150px;
    }
  }
  
  .info-btn {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    background-color: $gray-bg;
    padding: 5px;
    cursor: pointer;
    transition: color .5s ease;
  
    &:hover,
    &:focus {
      color: $logo-blue;
    }
  }

  .mute-btn {
    display: inline-flex;
    align-items: center;
    color: $white;
    font-size: $small-font-size;
    line-height: 1;
    margin: -5px;
    padding: 5px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $logo-blue;
    }
  }
}

.sample-quiz .end-screen {
  position: relative;
  background-color: $dark-bg-blue;
  color: $white;
  padding: 20px 20px 40px;
  overflow: hidden;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: space-between;
  min-height: 420px;
  align-items: flex-start;

  @media #{$md} {
    padding: 20px;
    justify-content: space-evenly;
    min-height: 0;
  }

  @media #{$sm} {
    padding: 20px 20px 0;
  }

  &.fade-appear {
    opacity: 0;
  }

  &.fade-appear-active {
    transition: .5s ease opacity;
    opacity: 1;
  }

  .heading {
    background-color: $darker-bg-blue;
    padding: 20px;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 0 30px;
    border-radius: 3px;
    text-align: center;

    @media #{$sm} {
      margin-bottom: 20px;
    }

    @media #{$xs} {
      text-align: left;
    }

    span {
      color: $logo-blue;
      margin-right: 4px;
    }
  }

  p {
    color: $white;
    line-height: 1.2;
  }

  .description {
    margin-bottom: 0;

    @media #{$xs} {
      font-size: $tiny-font-size;
    }
  }

  .title {
    font-size: $lead-font-size;
    line-height: 1.5;
    margin-bottom: 0;

    @media #{$xs} {
      font-size: $standard-font-size;
      font-family: var(--medium);
    }

    @media #{$sm} {
      font-size: $small-font-size;
      font-family: var(--light);
    }
  }

  .benefits-list {
    color: $white;
    list-style: none;
    margin: 0 0 20px 20px;
    padding: 0;
    width: 80%;
    font-family: var(--light);
    text-wrap: pretty;

    @media #{$md} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      width: 100%;
    }

    @media #{$sm} {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0 auto 20px;
    }

    @media #{$xs} {
      text-align: left;
      align-items: flex-start;
    }

    li {
      display: inline-block;
      margin-bottom: 0;
      width: 100%;
    }

    li + li {
      margin-top: 15px;
    }

    .circle--dot {
      margin-right: 10px;
      flex: 0 0 auto;
      width: 8px;
      height: 8px;
      position: relative;
      top: .125em;

      &.blue-lightest {
        background-color: $light-blue;
      }

      &.blue-light {
        background-color: $logo-blue-light;
      }

      &.blue {
        background-color: $logo-blue;
      }
    }
  }

  .btn {
    display: inline-flex;
    width: auto;
    margin-left: 20px;

    @media #{$sm} {
      margin: 0 auto;
    }
  }

  img {
    position: absolute;
    animation: fadeFromRight 1s;
    bottom: 0;
    right: 20px;
    transform: none;
    animation: unset;

    @media #{$sm} {
      position: relative;
      margin: 0 auto;
      width: auto;
      padding-left: 60px;
    }

    @media #{$md} {
      display: block;
    }

    @media #{$xs} {
      width: 80%;
      height: auto;
    }
  }

  .cta-meta {
    display: inline-block;
    font-size: 12px;
    margin: 4px 20px 0;
    max-width: 55%;
    text-wrap: pretty;

    @media #{$sm} {
      text-align: center;
      margin: 4px auto 20px;
      max-width: unset;
    }
  }
}

// Trophy animation on end screen

@keyframes fadeFromRight {
  from {
    opacity: 0;
    transform: translate(100%, 50%);
  }

  to {
    opacity: 1;
    transform: translate(0, calc(50% + 10px));
  }
}

// Full screen quiz

.landing-end-screen {
  background-color: $dark-bg-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $white;
  padding: 20px;

  & .emoji {
    font-size: 40px;
    margin-bottom: 10px;
  }

  & .title {
    color: $logo-blue;
    font-family: var(--light);
    font-size: 32px;
    margin-bottom: 12px;
  }

  & .description {
    font-size: $standard-font-size;
    color: $white;
  }

  & .benefits-list {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    text-align: left;

    & .circle {
      margin-right: 10px;
      flex: 0 0 auto;
    }

    & li {
      display: flex;
      align-items: center;
      line-height: 1.2;

      @media #{$xs} {
        font-size: $tiny-font-size;
      }
    }

    & li + li {
      margin-top: 15px;
    }

    @media #{$xs} {
      align-items: flex-start;
    }
  }
}
