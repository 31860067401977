.bullet-list {

  .list-item {
    display: table;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .bullet {
      display: table-cell;
      vertical-align: middle;
      padding-right: 15px;

      & > .circle:hover {
        background-color: $logo-blue;
        cursor: default;
      }
    }


    .text {
      display: table-cell;
      vertical-align: middle;

      h4 {
        font-family: var(--regular);

        & + p {
          font-size: 13px;
          color: $gray-text-light;
        }
      }

      p {
        font-family: var(--regular);
        color: $logo-blue;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0px;
      }
    }
  }
}

.icon-list {
  list-style: none;
  margin-left: 2.5em;
  padding-left: 0;

  > li {
    position: relative;
  }

  .icon-list__icon {
    position: absolute;
    left: -2em;
    text-align: center;
    width: 2em;
    line-height: inherit;
  }
}

.dropdown-menu .email-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;

  > li {
    margin: 0 0 15px 0;
    color: #495354;

    &.info {
      margin: 0;
    }
  }
}
