//
// Modals
// --------------------------------------------------

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
  background-color: rgba(34, 34, 34, 0.9);

  & > .modal {
    top: 10%;
    opacity: 1;

    @media #{$md} {
      top: 5%;
    }

    @media #{$xs} {
      top: 5px;
      left: 0px;
      right: 0px;
      max-width: 98%;
      margin: 0 auto;
    }
  }

  &.fade-in {
    background-color: rgba(34, 34, 34, 0);
    transition: background-color 0.2s ease-in 0s;

    & > .modal {
      top: -50%;
      opacity: 0;
      transition: top 0.3s linear 0s, opacity 0.2s linear 0s;
    }

    &.ReactModal__Overlay--after-open {
      background-color: rgba(34, 34, 34, 0.9);

      & > .modal {
        top: 10%;
        opacity: 1;

        @media #{$md} {
          top: 5%;
        }

        @media #{$xs} {
          top: 5px;
        }
      }
    }

    &.ReactModal__Overlay--before-close {
      background-color: rgba(34, 34, 34, 0);

      & > .modal {
        top: -50%;
        opacity: 0;
      }
    }
  }
}

// Background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
  background-color: #222;
  opacity: 0.8;
  // Fade for backdrop
  &.fade {
    opacity: 0;
  }

  &.fade.in {
    opacity: 0.9;
  }
}

// Base modal
.modal {
  width: 100%;
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1000;
  max-width: 390px;
  margin-left: -195px;
  background-color: #FFF;
  border-radius: 4px;
  padding: 30px 30px 0 30px;
  outline: none;
  overflow-y: auto;
  max-height: 800px;
  max-height: 90vh;

  &.fade {
    transition: opacity .3s linear, top .3s ease-out;
    top: -25%;
  }
  &.fade.in {
    top: 10%;
    @media #{$md} {
      top: 5%;
    }

    @media #{$xs} {
      top: 5px;
      left: 0px;
      right: 0px;
      margin: 0;
      max-width: 98%;
      margin: 0 auto;
      padding: 30px 15px 0 15px;
    }
  }

  @media #{$md} {
    top: 5%;
  }

  .message {
    margin-top: 30px;
  }

  h3.small {
    font-family: var(--light);
    font-size: 18px;
  }

  &.modal-small {
    max-width: 470px;
    width: 100%;
    margin-left: -235px;

    @media #{$md} {
      top: 5px;
      left: 0px;
      right: 0px;
      margin: 0;
      max-width: 98%;
      margin: 0 auto;
      padding: 15px 15px 0 15px;
    }
  }

  &.modal-medium {
    max-width: 520px;
    width: 100%;
    margin-left: -260px;

    @media #{$md} {
      top: 5px;
      left: 0px;
      right: 0px;
      margin: 0;
      max-width: 98%;
      margin: 0 auto;
      padding: 15px 15px 0 15px;
    }
  }

  &.modal-wide {
    max-width: 610px;
    width: 100%;
    margin-left: -305px;

    @media #{$md} {
      top: 5px;
      left: 0px;
      right: 0px;
      margin: 0;
      max-width: 98%;
      margin: 0 auto;
      padding: 30px 15px 0 15px;
    }
  }

  &.modal-gray {
    padding: 0;

    @media #{$md} {
      &.fade.in {
        padding: 0;
        max-width: 98%;
      }
    }

    .modal-header {
      background: #ffffff;
      color: $logo-blue;
      margin: 0;
      font-family: var(--regular);
      font-size: 16px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;

      h3 {
        margin: 0 0 0 30px;
        color: $logo-blue;
        font-size: 14px;
        line-height: 14px;
        display: inline-block;
        font-family: var(--regular);
      }

      .close {
        display: flex;
        float: none;
        margin-right: 25px;
        margin-top: 0;
      }
    }

    .modal-body {
      position: relative;
      overflow-y: auto;
      max-height: 400px;
      padding: 30px;
      background-color: $gray-bg-dark;
    }
  }
}

.modal-header {
  border-bottom: 1px solid $gray-border;

  h3 {
    line-height: 30px;
    margin-top: -5px;
  }

  .close {
    margin-top: -2px;
    margin-left: 5px;
    float: right;
    width: 19px;
    height: 18px;
    cursor: pointer;
    z-index: 1001;
    padding: 15px;
    background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center
  }

}

// Body (where all modal content resides)
.modal-body {
  position: relative;

  & > p {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: var(--regular);

    &:first-child {
      margin-top: 30px;
    }
  }

  & .btn:not(.btn-block) {
    display: inline-flex;
  }

  & .circle-group {
    & > .text-left {
      width: 50%;
      float: left;
      margin-bottom: 35px;
      @media #{$xs} {
        display: none;
      }
    }

    & > .icons-right {
      width: 50%;
      float: right;
      text-align: right;
      margin-bottom: 35px;
      @media #{$xs} {
        width: 100%;
        text-align: left;
      }
    }
  }

  @media #{$xs} {
    max-height: calc(100vh - 75px);
  }

}

#training_cta_modal {
  & .l-flex-row {
    margin-top: 30px;

    @media #{$md} {
      margin-bottom: 0px;
      flex-direction: row;

      & > div {
        margin-bottom: 0;
      }
    }
  }
}

.terminology-modal {
  padding-bottom: 15px;

  @media #{$xs} {
    padding-bottom: 0;
  }
}

.modal-footer {
  padding-top: 30px;
  border-top: 1px solid $gray-border;
  padding-bottom: 30px;

  & > p {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: var(--regular);
  }
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
  &.in {
    opacity: 1;
  }
}

#modal_setup_mission_complete {
  & > .modal-body {
    text-align: center;

    & > .modal-img {
      margin: 20px 0 10px;
      width: 125px;
      height: 125px;
    }

    & > .btn {
      margin: 0 0 30px;
    }
  }
}
.modal-options {
  display: flex;
  margin: 30px 0;
  justify-content: flex-end;

  .modal-options__option.modal-options__option {
    margin: 0 0 0 5px;

    @media #{$sm} {
      width: 100%;
      display: block;
      margin: 5px 0 0 0;
    }
  }

  @media #{$sm} {
    margin: 10px 0 30px;
    display: block;
  }
}
