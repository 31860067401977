// _VARIABLES.CSS.SCSS
// Variables used across multiple stylesheets
// --------------------------------------------------------------------------

// if a variable is only used by a single module, define it inside that
// module. Reserve _variables.scss for things that are truly global.


// TYPOGRAPHY
// ----------

:root {
  --regular: PlutoSansRegularSubset, Helvetica, Arial, sans-serif;
  --light: PlutoSansRegularSubset, Helvetica, Arial, sans-serif;
  --medium: PlutoSansRegularSubset, Helvetica, Arial, sans-serif;
  --light-italic: PlutoSansRegularSubset, Helvetica, Arial, sans-serif;
  // default value for virtual hight variable
  // will be overwritten via javascript in app/javascript/init.coffee
  --vh: 100vh;
  // default value for study unit complete action box
  // will be overwritten via javascript in controllers/study_unit_controller.js
  --su-complete-box-height: 160px;
  // Calculates where the grey line on the left of the SU should stop before
  // it reaches the bottom of the complete box
  --su-learning-path-offset: calc(var(--su-complete-box-height) / 2); 
}

$medium-font-weight: 400;

$header-headline-font-size: 48px;
$headlines-font-size: 36px;
$lead-font-size: 24px;
$standard-font-size: 18px;
$small-font-size: 16px;
$tiny-font-size: 14px;
$mini-font-size: 12px;
$smallest-font-size: 10px;

$header-headline-line-height: 52px;
$headline-line-height: 40px;
$copy-line-height: 24px;

$main-padding-md: 32px;
$main-padding-xs: 15px;

// COLORS
// ------

$gray-text: #495354;
$gray-text-light: #676F77;
$gray-text-lightest: #acafb2;
$gray-border: #d8d8d8;
$gray-border-dark: #b8b8b8;
$gray-bg-dark: #f3f3f3;
$gray-bg-light: #ededed;
$gray-bg: #f7f7f7;

$logo-blue: #0099FF;
$logo-blue-bg: #e5f4ff;
$logo-blue-light: #99d6ff;
$logo-blue-lightest: #cceaff;
$dark-bg-blue: #3A5062;
$darker-bg-blue: #2d3e4d;
$light-blue: #ebf7ff;
$light-yellow: #fff4e2;
$yellow-text: #c67400;
$gold: #FFC026;
$gold-bg-light: #FFECBD;
$gold-text: #966C09;
$logo-blue-darkened: #0188e4;
$white: #ffffff;
$red: #ec8e8e;
$red-darkened: #ec7676;
$red-bg-light: #FDEDEE;
$green: #5ed29f;
$green-darkened: #48C891;
$green-bg-light: #E7F9F1;

$google-blue: #4285F4;

// GOLD SHIMMER

@mixin gold-shimmer($duration) {
  -webkit-mask: linear-gradient(-60deg,
              black 0%,
              black 40%,
              rgba(0, 0, 0, .7) 40%,
              rgba(0, 0, 0, .7) 41%,
              black 41%,
              black 42%,
              rgba(0, 0, 0, .7) 42%,
              rgba(0, 0, 0, .7) 44%,
              black 44%,
              black 100%)
                right / 300% 100%;
  background-repeat: no-repeat;
  animation: shimmer $duration ease-in-out 2s infinite;
}

// GRID
// ------

$max-width: 940px; // of the outer container
$column: golden-ratio(60px, 1);
$gutter: golden-ratio(20px, 1);
$visual-grid: false; // set to true to see the grid
$visual-grid-color: #CCC;

$xs: "screen and (max-width : 479px)";
$sm: "screen and (max-width : 763px)";
$md: "screen and (max-width : 1023px)";
$lg: "screen and (min-width : 1024px)";
$xl: "screen and (min-width : 1360px)";
$sm-md-only: "screen and (max-width : 1023px) and ( min-width : 480px)";
$md-lg-only: "screen and (min-width : 764px)";

// PROGRESS
// ------

$progress-donut-size: 40px;
