.bookmark {
  cursor: pointer;
  position: relative;

  &--loading {
    cursor: initial;
    color: $gray-text-light;
  }

  .tooltip {
    z-index: 100000;
    display: inline;
  }
}

.bookmarks {
  @media #{$lg} {
    & .sidebar  {
      display: none;
    }

    & .content {
      margin-left: 0;
    }
  }
}

.bookmarks-list {
  list-style: none;
  margin: 0;
  border-radius: 3px;
  border: 1px solid $gray-border;
}

.bookmarks-list__item {
  display: block;
  border-bottom: 1px solid $gray-border;
  padding: 10px 15px;

  &:last-of-type {
    border-bottom: 0;
  }
}

.bookmarks-list__content {
  display: flex;
  align-items: center;

  & .bookmarks-list__title {
    flex: 1;
    font-size: $tiny-font-size;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .bookmarks-list__controls {
    .btn-link {
      margin-right: 0;
      height: unset;
      padding: 10px;
    }

    .fa {
      font-size: 20px;
    }
  }
}
