.anatomyterm {
  border: 1px solid $gray-border;
  overflow: hidden;
  text-decoration: none;

  & .anatomyterm__image {
    padding-bottom: 100%;
    position: relative;
    background-color: $white;

    & > img {
      height: 100%;
      width: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: absolute;
    }

    & > .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .anatomyterm__bottom {
    width: 100%;
    padding: 0 15px;
    background-color: $gray-bg-dark;
    height: 85px;
    display: table;

    .anatomyterm-title {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 14px;
      line-height: 21px;
      font-family: var(--light);
      color: $logo-blue;
    }
  }
}

.anatomyterm-thumb {
  position: relative;
  display: inline-block;
  border: 1px solid #dcdcdc;
  height: 100px;
  width: 100px;
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: border 0 linear;

  &:hover {
    border: 1px solid $dark-bg-blue;
    transition: border 0.4s linear;
  }

  & img {
    height: 98px;
    width: 98px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: absolute;
  }
}
