.quiz-mode-selector-modal.modal {
  max-width: 610px;
  width: 100%;
  padding: 30px 60px;
  margin-left: 0;
  left: 50%;
  transform: translateX(-50%);

  @media #{$md} {
    padding: 30px;
  }

  @media #{$sm} {
    display: grid;
    align-items: center;
    grid-template-rows: auto 1fr auto;
    top: 0 !important;
    bottom: 0;
    padding: 15px;
    max-height: 100vh;
    max-width: 100%;
    border-radius: 0;
  }

  &.selected {
    & .modal-content {
      @media #{$sm} {
        margin: 0 0 auto;
      }
    }
  }

  &.modal-with-banner {
    padding-top: 0;

    @media #{$md} {
      padding: 0 30px 30px;
    }

    @media #{$sm} {
      padding: 0 0 15px;
      grid-template-rows: auto auto 1fr auto;
    }
  }

  & .modal-header {
    border: 0;
  }

  & .preview-banner {
    margin: 0 -60px 30px;
    background-color: $red;
    color: $white;
    text-align: center;
    padding: 8px 15px;

    @media #{$sm} {
      margin-bottom: 15px;
    }

    & .title {
      font-size: $tiny-font-size;
      font-family: var(--medium);

      & .fa {
        margin-right: 4px;
      }
    }

    & .content {
      font-size: 12px;
      font-family: var(--light);

      & .fa {
        margin-left: 4px;
      }
    }

    & a {
      font-size: 12px;
      font-family: var(--medium);
      color: $white;
    }
  }
}

.quiz-mode-selector {
  &.modal-with-banner {
    .info,
    .control {
      filter: grayscale(1);
    }
  }

  & .modal-content {
    transition: margin .5s ease;
    margin: 0;
  }

  & .modal-title {
    font-size: $standard-font-size;
    text-align: center;
    color: $gray-text;

    @media #{$sm} {
      font-size: $tiny-font-size;
      align-self: flex-start;
      margin-bottom: 10px;
    }
  }

  & .quiz-start-btn {
    margin-top: 36px;
    width: 100%;

    @media #{$sm} {
      margin-top: 28px;
      align-self: flex-end;
    }
  }

  & .controls {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }

  & .control-group {
    background-color: $gray-bg;
    border-radius: 4px;
    margin: 0;
    position: relative;

    h4 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: var(--medium);
      font-size: $mini-font-size;
      color: $gray-text;
      margin: 0;
      padding: 16px;
      line-height: 1.2;
      cursor: pointer;

      @media #{$sm} {
        font-size: 11px;
      }
    }

    & > .group-item {
      padding: 10px 16px 0 16px;
      border-radius: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      &:last-child {
        padding-bottom: 16px;
      }

      &.expandable {
        flex-direction: column;
        justify-content: flex-start;
        align-items: normal;
      }

      & .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > .info {
          display: flex;
        }
      }

      & .description {
        font-size: $mini-font-size;
        color: $gray-text-light;
        margin: 0;
        line-height: 1.2;

        @media #{$sm} {
          font-size: 10px;
        }
      }

      & .control {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      & .edit-button {
        flex-shrink: 0;
        background: none;
        border: 0;
        appearance: none;
        font-size: $mini-font-size;
        color: $logo-blue;
        font-family: var(--light);
        cursor: pointer;
        line-height: 1.2;
        margin-right: 5px;

        @media #{$sm} {
          font-size: 10px;
        }

        &.active .fa {
          transform: rotate(180deg);
        }

        & .fa {
          transition: transform .25s ease;
          font-size: 10px;
          margin-left: 4px;
        }
      }

      & .divider {
        margin: 20px 0;
      }


      & label {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: $mini-font-size;

        &:last-of-type {
          margin-bottom: 0;
        }

        @media #{$sm} {
          font-size: $smallest-font-size;
        }

        & span.checkbox {
          width: 18px;
          height: 15px;
          margin-right: 6px;
        }
      }

      & .expandable-area {
        grid-area: expandable;
        border-radius: 4px;
        padding: 16px;
        margin-top: 10px;
        background-color: #0099ff0d;

        & .summary {
          display: flex;
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid $gray-border;

          & .description {
            flex: 1 1 auto;
          }

        }
      }
    }
  }

  & .control-item {
    display: grid;
    grid-template-areas:
      "title controls"
      "expandable expandable";
    grid-template-columns: 50% 50%;
    align-items: center;
    background-color: $gray-bg;
    border-radius: 4px;
    padding: 16px;
    position: relative;

    &--toggle {
      grid-template-columns: 1fr;
    }

    & .info {
      grid-area: title;
    }

    & .sub-text {
      border-top: 1px solid $gray-border;
      grid-area: expandable;
      font-size: 12px;
      margin: 12px 0 0;
      line-height: 1.2;
      padding-top: 12px;
    }

    & .control {
      grid-area: controls;
      align-content: center;
      text-align: right;
    }

    & .preview-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba($gray-bg, .9);
      color: $red;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: var(--medium);
      font-size: $tiny-font-size;
      padding: 8px 15px;

      @media #{$sm} {
        font-size: 12px;
      }

      & .fa {
        margin-right: 4px;
      }
    }

    & .expandable-area {
      grid-area: expandable;

      & .summary {
        display: flex;
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $gray-border;

        & .description {
          flex: 1 1 auto;
        }

        & .edit-button {
          margin-left: auto;
          flex-shrink: 0;
          background: none;
          border: 0;
          appearance: none;
          font-size: 14px;
          color: $logo-blue;
          font-family: var(--regular);
          cursor: pointer;

          &.active .fa {
            transform: rotate(180deg);
          }

          & .fa {
            transition: transform .25s ease;
            font-size: 10px;
            margin-left: 4px;
          }
        }
      }
    }

    & .label {
      position: relative;
      margin: 8px 0 -8px;
      font-size: $mini-font-size;

      @media #{$sm} {
        font-size: $smallest-font-size;
      }
    }

    & .title {
      font-family: var(--medium);
      font-size: $mini-font-size;
      color: $gray-text;
      margin: 0;
      line-height: 1.2;

      @media #{$sm} {
        font-size: $smallest-font-size;
      }
    }

    & .description {
      font-size: $mini-font-size;
      color: $gray-text-light;
      margin: 0;
      line-height: 1.2;

      @media #{$sm} {
        font-size: $smallest-font-size;
      }
    }
  }

  & .slider-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .slider-value {
    font-size: 10px;
    font-family: var(--light);
    color: $gray-text-light;
  }

  .toggle-switch {
    position: relative;
    box-sizing: border-box;

    & input[type="checkbox"] {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }

    & label {
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin: 0;
    }

    & label:before {
      content: '';
      width: 40px;
      height: 22px;
      background: $white;
      border: 1px solid $gray-border;
      position: relative;
      display: inline-block;
      border-radius: 10rem;
      transition: 0.2s ease-in;
    }

    & label:after {
      content: '';
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      left: 2px;
      top: 2px;
      z-index: 2;
      background: $gray-border;
      box-sizing: border-box;
      transition: 0.2s ease-in;
    }

    & input[type="checkbox"]:checked + label:after {
      left: 20px;
      background: $logo-blue;
    }
  }

  &.exam-conditions .toggle-switch {
    & input[type="checkbox"]:checked + label:before {
      border-color: $gold;
    }

    & input[type="checkbox"]:checked + label:after {
      content: url("~images/quiz/mini_trophy.svg");
      background: $gold;
      @include gold-shimmer(5s);
    }
  }
}

.quiz-modes {
  display: flex;
  gap: 20px;

  @media #{$sm} {
    flex-direction: column;
    gap: 8px;
  }

  & .exam-mode,
  & .study-mode {
    flex: 1 1 50%;
    cursor: pointer;

    @media #{$sm} {
      flex: 1 1 100%;

      &:has(.container.selected) {
        order: -1;
      }
    }

  }

  & .container {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $gray-border;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    transition: border-color .25s ease;

    @media #{$sm} {
      flex-direction: row;
    }
  }

  & .switcher {
    display: none;
    text-align: center;
    color: $logo-blue;
    font-size: 11px;

    @media #{$sm} {
      display: block;

      &.selected {
        display: none;
      }
    }

    & .fa {
      margin-right: 4px;
    }
  }

  &:not(.active) {
    .switcher {
      display: none;
    }
  }

  @media #{$sm} {
    &.active .container:not(.selected) {
      display: none;
    }
  }

  & .image {
    background-color: $gray-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    transition: background-color .25s ease;

    @media #{$sm} {
      aspect-ratio: 1;
      min-width: 80px;

      img {
        height: 40px;
        width: auto;
      }
    }
  }

  & .content {
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media #{$sm} {
      padding: 12px;
      text-align: left;
    }
  }

  & .title {
    font-family: var(--light);
    font-size: $standard-font-size;
    margin-bottom: 4px;
    line-height: 1.2;

    @media #{$sm} {
      font-family: var(--regular);
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  & .description {
    font-size: $tiny-font-size;
    margin-bottom: 8px;
    line-height: 1.5;

    @media #{$sm} {
      font-size: $mini-font-size;
    }
  }

  & .hint {
    font-size: 10px;
    color: $gray-text-light;
    margin: auto 0 0;
    line-height: 1.2;
  }

  & .icon-container {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    background-color: $logo-blue;
    color: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 24px;
    height: 24px;
    font-size: $tiny-font-size;
    transition: opacity .25s ease;

    @media #{$sm} {
      right: unset;
      left: 4px;
      top: 4px;
      width: 16px;
      height: 16px;
      font-size: 8px;
    }
  }

  & .container.selected {
    border-color: $logo-blue;

    & .image {
      background-color: $logo-blue-bg;
    }

    & .icon-container {
      opacity: 1;
    }
  }
}

.embedded-quiz-container {
  & .quiz-mode-selector {
    background-color: $gray-bg;
    padding: 20px 30px;

    @media #{$sm} {
      padding: 16px;
    }

    & .modal-title {
      font-size: $small-font-size;
      font-family: var(--regular);
    }

    & .modal-content {
      padding: 0;
    }

    & .modal-footer {
      padding: 0;
    }

    & .control-item {
      background-color: $white;
    }

    & .control-group {
      background-color: $white;
    }

    & .quiz-modes {
      & .container {
        background-color: $white;
      }
    }
  }
}

.quiz-mode-selector.exam-conditions {
  .exam-mode {
    .container {
      border-color: $gold;

      .image {
        background-color: $gold-bg-light;
      }

      .icon-container.show {
        background-color: $gold;
      }
    }

    .title {
      color: $gold;
    }
  }

  .quiz-start-btn {
    background-color: $gold;
  }

  input[type="range"]::-webkit-slider-thumb {
    background-color: $gold;
  }
}
