// FOOTER
// ----------

footer {
  background-color: #ececec;
  margin-bottom: -18px;
  margin-top: 50px;
  padding-bottom: 45px;

  @media #{$md} {
    text-align: center;
  }

  & .l-container > .bottom-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$md} {
      flex-direction: column;
    }

    & .icons-container {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      justify-content: center;
      @media #{$md} {
        margin-bottom: 18px;
      }
    }

    & .circle {
      margin: 0;
    }
  }
}

footer > .l-container > .logos {
  text-align: center;
  padding-top: 38px;
  opacity: 0.8;
  border-bottom: 1px solid $gray-border;
  min-height: 127px;

  & a:last-child {
    /*this is the UC Denver logo */
    opacity: 0.7;
  }

  > a {
    margin-bottom: 38px;
    margin-right: 17px;
    margin-left: 17px;
    max-width: 100%;
    display: inline-block;

    & > img {
      max-width: 100%;
    }
  }
}

// FOOTER SITEMAP
// ----------

.l-sitemap {
  padding-top: 55px;
  margin-bottom: 60px;

  @media #{$md} {
    margin-bottom: 50px;
    text-align: center;
  }

  .l-sitemap__title {
    margin-top: 0;
    font-family: var(--regular);
    color: $logo-blue;
    line-height: 21px;
    margin-bottom: 18px;
    text-transform: uppercase;
    font-size: $tiny-font-size;
    font-weight: normal;
  }

  & ul {
    margin-top: 0;

    & > li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 20px;
      }

      & > a {
        font-family: var(--regular);
        color: $gray-text-light;
        font-size: 14px;
      }
    }
  }

  & p {
    color: $gray-text-light;
  }

  & .quality-guidelines {
    overflow-wrap: break-word;
    margin-bottom: 20px;

    & > a {
      height: 80px;
      display: block;
      margin-bottom: 20px;
    }
  }

  & .logos {
    text-align: left;
    margin-top: 20px;
    opacity: 0.8;

    & a:nth-child(3) {
      /*this is the UC Denver logo */
      opacity: 0.7;
    }

    > a {
      margin-bottom: 10px;
      margin-right: 10px;
      max-width: 100%;
      display: inline-block;

      & > img {
        max-width: 100%;
      }
    }

    @media #{$md} {
      margin-bottom: 50px;
      text-align: center;
    }

  }
}
