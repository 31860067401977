.listingbox {
  border: 1px solid $gray-border;
  margin-bottom: 30px;
  border-radius: 2px;

   @media #{$md} {
     width: 100%
   }

  .listingbox__image {
    float: left;
    height: 165px;
    width: 165px;
    position: relative;

    @media #{$sm} {
      display: none;
    }

    @media #{$xs} {
      display: block;
      float: none;
      text-align: center;
      height: 205px;
      width: 100%;
      border-bottom: 1px solid $gray-border;
    }

    img {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: absolute;

      @media #{$xs} {
        left: calc(50% - 102.5px);
      }
    }
  }

  .listingbox__topic {
    height: 165px;
    margin-left: 165px;
    background-color: #fff;
    border-left: 1px solid #ececec;
    padding: 26px 30px;

    @media #{$md} {
      width: calc(100% - 165px);
    }

    @media #{$sm} {
      margin-left: 0;
      border-left: none;
      height: auto;
      width: 100%;
    }

    @media #{$xs} {
      padding: 15px;

    }

    & > .listingbox__title.listingbox__title {
      max-width: 433px;
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 30px;
      margin-top: 0;
      font-family: var(--regular);
      color: $logo-blue;
      font-weight: normal;
      clear: none;

      @media #{$xs} {
        font-size: 16px;
        line-height: 16px;
      }
    }

    & > .listingbox__description {
      max-width: 433px;
      font-family: var(--light);
      color: $gray-text-light;
      font-size: 13px;
      margin-top: 0;
      line-height: 20px;

      @media #{$xs} {
        margin-bottom: 0;
      }

      & > a {
        color: $gray-text-light;

        &:hover {
          color: $gray-text-light;
        }
      }
    }

    & > .listingbox__icons {
      font-family: var(--light);

      @media #{$xs} {
        display: none;
      }

      & > .listingbox__icon {
        display: inline-block;
        border-right: 1px solid #ececec;
        margin-right: 20px;
        padding-right: 20px;
        color: $gray-text-lightest;

        &:last-child {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }

        & > .fa {
          margin-right: 5px;
        }
      }

      & .enable-tooltip > .tooltip {
        margin-top: 27px;
        margin-left: -105px;
      }

      & img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  .listingbox__content {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > .trainingunit {
      display: flex;
      align-items: center;
      border-top: 1px solid $gray-border;
      border-left: 4px solid $gray-bg;
      padding: 20px;
      background-color: $gray-bg;

      @media #{$sm} {
        padding: 15px;
      }

      @media #{$xs} {
        padding-left: 10px;
      }

      &.completed {
        border-left-color: $logo-blue;
        background-color: $gray-bg-dark;
      }

      & .trainingunit-topic {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: auto;
        max-width: 100%;
        min-width: 0;
        gap: 15px;

        & .trainingunit-title {

          & .hourglass-tooltip {
            display: none;

            &.show {
              display: block;
            }
          }

          & .fa-hourglass-now {
            color: $red;
          }
        }
      }

      & .trainingunit-progress-circle {
        width: $progress-donut-size;
        height: $progress-donut-size;
        position: relative;

        & .progress-donut-container {
          & .colored-doughnut {
            width: $progress-donut-size !important;
            height: $progress-donut-size !important;
          }

          & .fa-graduation-cap {
            color: #E4E4E4;
          }

          & .full-mastery-badge {
            & .fa {
              color: $gold;
            }
          }
        }
      }

      & .completed-badge {
        width: $progress-donut-size;
        height: $progress-donut-size;
        background: $gold;
        @include gold-shimmer(10s);
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        & .fa {
          color: $gold-text;
          font-size: $small-font-size;
        }
      }

      & .progress-donut-icon {
        position: absolute;
        display: inline-flex;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        aspect-ratio: 1;
      }

      & .trainingunit-details {
        min-width: 0;
        flex: 1;
      }

      & .trainingunit-title {
        font-family: var(--regular);
        font-size: 14px;
        color: $logo-blue;
        margin-bottom: 10px;
        line-height: 18px;

        @media #{$xs} {
          margin-bottom: 5px;
        }

        @media #{$xs} {
          margin-bottom: 0;
        }
      }

      & .trainingunit-description {
        font-family: var(--light);
        font-size: 13px;
        display: inline-block;
        color: $gray-text-light;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;

        @media #{$xs} {
          display: none;
        }
      }

      & .badge.badge--small {
        font-size: 8px;
        min-height: 13px;
        line-height: 14px;
        padding: 0 8px;
        margin-right: 5px;
      }

      & .trainingunit-cta {
        text-align: right;
        margin-left: 15px;

        @media #{$sm} {

          & .btn.btn-small {
            height: auto;
            padding: 7px;
            font-size: 9px;
          }
        }

        @media #{$xs} {
          margin-left: 10px;
        }
      }

      & .trainingunit-progress {
        display: flex;
        margin-right: 15px;

        @media #{$xs} {
          margin-right: 10px;
        }

        & .btn.btn-small {
          height: auto;
          padding: 8px;

          @media #{$sm} {
            padding: 6px;
          }
        }

        & > .videos-cta {
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &[data-hover] {
            width: 65px;
            transition: width .3s linear;
            cursor: default;


            &:before {
              content: '';
              text-overflow: ellipsis;
              word-wrap: normal;
              white-space: nowrap;
              min-height: 4px;
              display: none;
            }
          }

          &[data-hover]:hover, &[data-hover]:active {
            width: 89px;

            & > span {
              display: none;
            }

            &:before {
              display: block;
              content: attr(data-hover);
            }
          }
        }
      }
    }
  }

  .listingbox__completed-items {
    display: flex;
    margin-bottom: 20px;

    @media #{$xs} {
      margin-bottom: 13px;
    }

    & .item {
      flex: 1 1 auto;
      max-width: 25px;
      height: 4px;
      border-radius: 2px;
      background-color: $logo-blue-lightest;
      margin-right: 4px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    & .complete {
      background-color: $logo-blue;
    }
  }
}

.listingbox-tooltip {
  width: 150px;
}
