.select {
  position: relative;
  min-width: 0;
}

.select__selector {
  display: flex;
  min-width: 0;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
  background: $white;
  border: 1px solid $gray-border;
  color: $gray-text;
  font-size: 12px;
  line-height: 21px;
  border-radius: 100px;
  padding: 1px 10px 0 10px;
  transition: border-color .2s ease;
  word-break: normal;

  .fa {
    margin-left: 10px;
  }

  &:hover,
  &:focus,
  &[aria-label="close menu"] {
    border-color: $logo-blue;
  }
}

.select__dropdown {
  position: absolute;
  min-width: 180px;
  top: calc(100% + 4px);
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  border: 1px solid $gray-border;
  background: $white;
  z-index: 499;
  max-height: 300px;
  overflow: auto;

  @media #{$sm} {
    max-height: 40vh;
  }

  & ul {
    margin: 0;
    padding: 0;
  }
}

.select__item {
  padding: 10px 15px;
  margin: 0;
  border-bottom: 1px solid $gray-border;
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  color: $gray-text;
  font-size: 12px;
  cursor: pointer;
  transition: background-color .2s ease;

  &:hover,
  &:focus {
    background-color: $light-blue;
  }

  &--selected {
    background-color: $logo-blue-lightest;
  }
}

.select__input-value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}

.select__section-heading {
  background-color: $gray-bg;
  font-size: 12px;
  border-bottom: 1px solid $gray-border;
  padding: 10px 15px;
  color: $gray-text;
  font-family: var(--medium);
  position: sticky;
  top: 0;
  z-index: 100;
}
