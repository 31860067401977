.tag {
  color: #9aa7b2;
  border: 1px solid #9aa7b2;
  font-size: 10px;
  font-family: var(--medium);
  height: 22px;
  line-height: 21px;
  border-radius: 15px;
  display: inline-block;
  padding: 0 10px;
  text-transform: uppercase;
  background-color: $gray-bg-dark;
  vertical-align: text-top;

  &.tag-filter {
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px;
    background-color: inherit;

    &.tag-muscle-facts {
      background-color: #fff;
    }

    &.tag-others {
      background-color: $gray-bg-dark;
    }

    &.tag-selected {
      color: $white;
      border: 1px solid $logo-blue;
      background-color: $logo-blue;
    }

    &:hover {
      color: $logo-blue;
      border: 1px solid $logo-blue;

      &.tag-selected {
        color: $white;
      }
    }
  }

}

[data-filter-list] .tag:not(.tag--premium):not(.tag--free) {
  cursor: pointer;

  &:hover {
    color: $logo-blue;
    border: 1px solid $logo-blue;
  }
}

.header-tags {
  float: right;
  margin-top: 2px;
  padding-left: 10px;

  @media #{$sm} {
    display: none;
  }
}
