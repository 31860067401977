.banner {
  display: flex;
  flex-direction: row;
  font-size: $tiny-font-size;
  line-height: 1.2;
  padding: 15px;
  margin: 0 0 30px;

  a {
    text-decoration: underline;
    font-family: var(--medium);
    color: inherit;
  }

  ul:last-of-type {
    margin-bottom: 0;
  }

  @media #{$md} {
    margin-bottom: 15px;
  }

  &__icon {
    flex: 0 0 auto;
    padding-right: 10px;
  }

  &__content {
    flex: 1;
  }

  &__title {
    margin-top: 2px;
    font-family: var(--medium);

    + .banner__details {
      margin-top: 10px;
    }
  }

  &__close {
    cursor: pointer;
    padding: 15px 15px 0 15px;
    margin-right: -15px;
    margin-top: -15px;
  }

  &--info {
    background-color: $logo-blue-bg;
    color: $logo-blue;
  }

  &--warning {
    background-color: $light-yellow;
    color: $yellow-text;

    & .circle {
      background-color: $yellow-text;
    }
  }

  &--fade-in {
    animation: fade_in 1.5s forwards;
  }
}
