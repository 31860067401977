#search {
  & > .fullscreen-search__root {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 600;
    min-height: 100vh;
    background-color: $white;

    .fullscreen-search__header {
      background-color: $gray-bg;

      @media #{$md} {
        display: none;
      }
    }

    .fullscreen-search__header-row {
      @extend .l-container;

      .logo {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 0 0 4px 4px;
        overflow: hidden;

        svg {
          display: block;
        }
      }
    }

    .fullscreen-search__top-section {
      background-color: $gray-bg;
      padding: 22px 0;

      @media #{$md} {
        padding: 15px 0;
      }

      .ais-SearchBox {
        margin: 0 auto;

        @media #{$md} {
          margin: 0;
          flex-grow: 1;
        }

        form.ais-SearchBox-form {
          width: 100%;
          height: 44px;
          background-color: $white;
          vertical-align: top;
          border-radius: 2px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
          margin: 0;

          input.ais-SearchBox-input {
            margin: 14px 0 0 20px;
            width: calc(100% - 68px);
            height: 18px;
            border: 0;
            padding: 0;
            background: $white;
            outline: none;
            display: inline;
            color: $gray-text;

            &:focus {
              box-shadow: none;
            }
          }

          .ais-SearchBox-submit {
            margin-right: 4px;
            float: right;
          }

          button.ais-SearchBox-submit, button.ais-SearchBox-reset {
            background-color: $white;
            border: 0;
            height: 44px;
            width: 44px;
            outline: none;
            cursor: pointer;
          }

          svg.ais-SearchBox-submitIcon, svg.ais-SearchBox-resetIcon {
            width: 21px;
            height: 21px;
            vertical-align: middle;
            fill: $gray-text-lightest;
          }

          .ais-SearchBox-reset {
            display: none;
          }
        }
      }

      .ais-Hits-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    }

    .fullscreen-search__bottom-section {
      padding-top: 22px;

      @media #{$md} {
        padding-top: 0;
      }

      &.l-container {
        @media #{$md} {
          margin: 0;
          padding: 0;
          max-width: 100%;
        }
      }

      & > .spinner {
        height: calc(100vh - 210px)
      }
    }
  }
}

.fullscreen-search__results {
  border-top: 1px solid $gray-border;
  border-bottom: 1px solid $gray-border;
  width: 100%;
  margin-bottom: 32px;

  @media #{$lg} {
    border-radius: 2px;
    border-left: 1px solid $gray-border;
    border-right: 1px solid $gray-border;
    box-shadow: 0 0 10px 0 rgba(59, 59, 59, 0.15);
  }
}


.fullscreen-search__no-results {
  padding: 30px 32px;
  font-family: var(--regular);
  font-size: 14px;
  color: $gray-text;

  @media #{$xs} {
    padding: 30px 15px;
  }

  .fullscreen-search__results > & {
    border-bottom: 1px solid $gray-border;
  }
}

.search-autocompleter {
  & > ul {
    margin: 0;
    padding: 0;

    li {
      color: $gray-text;
      list-style-type: none;
    }

    & > .search-autocompleter__section {
      &:not(:last-of-type) {
        border-bottom: 1px solid $gray-border;
      }

      & > .search-autocompleter__section-title {
        height: 41px;
        padding: 0 32px;
        border-top: 0;
        border-bottom: 1px solid $gray-border;
        font-size: 14px;
        font-family: var(--regular);
        line-height: 41px;
        color: $gray-text-light;
        background-color: $gray-bg;

        @media #{$xs} {
          padding: 0 15px;
        }
      }

      &:first-of-type > .search-autocompleter__section-title {
        @media #{$md} {
          box-shadow: inset 0px 7px 7px -5px #ddd;
        }
      }

      & > .search-autocompleter__section-hits {
        margin: 0;

        &:not(:last-of-type) {
          border-bottom: solid 1px $gray-border;
        }
      }
    }

    .fullscreen-search__show-more {
      overflow: hidden;
      margin-bottom: 15px;
      font-size: 12px;
      font-family: var(--regular);

      & > a {
        float: right;
      }
    }

    .search-autocompleter__media-type {
      display: table;
      width: 100%;
      padding-top: 15px;

      @media #{$xs} {
        display: block;
      }

      & > .search-autocompleter__media-type-title {
        width: 133px;
        font-family: var(--regular);
        font-size: 11px;
        display: table-cell;
        color: $gray-text;
        padding-left: 32px;

        @media #{$xs} {
          width: 100px;
          padding-left: 15px;
          display: block;
          margin-bottom: 10px;
        }

        & > .fa {
          margin-right: 8px;
        }
      }

      .search-autocompleter__media-type-hits {
        font-size: $tiny-font-size;
        display: table-cell;
        padding-left: 20px;
        padding-right: 30px;
        margin-left: 15px;

        @media #{$xs} {
          display: block;
          padding-left: 0;
          line-height: 19px;
          padding-right: 15px;
        }

        & > li {
          margin-bottom: 13px ;
          font-family: var(--light);

          &.search-autocompleter__footer {
            margin: 15px 0;
          }

          a {
            color: $gray-text;
            font-family: var(--light);

            &:hover {
              text-decoration: underline;
            }

          }

          button.fullscreen-search__show-more--link {
            color: $logo-blue;
            display: inline-block;
            font-family: var(--light);
            font-size: 12px;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;

            &:hover {
              text-decoration: underline;
            }
          }

          &.search-autocompleter__hit--with-snippet > a {
            font-family: var(--regular);
          }
        }
      }
    }

    .ais-Highlight-highlighted,
    .ais-Snippet-highlighted {
      font-family: var(--medium);
      color: $logo-blue;
    }

    & .search-autocompleter__snippet {
      color: $gray-text-light;
      font-size: 12px;
      margin-top: 2px;
      line-height: 1.6;
      letter-spacing: 0.1px;
    }

    & .search-autocompleter__snippet-inner {
      max-width: 75%;

      @media #{$sm} {
        max-width: 100%;
      }
    }
  }

  .fullscreen-search__study-units {
    padding: 20px 30px 0;

    @media #{$xs} {
      padding: 15px 15px 0;
    }

    & ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 0 20px 0;

      & > * {
        flex-basis: calc(50% - 10px);
        margin-top: 20px;

        &:nth-child(1), &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(2n) {
          margin-left: 20px;
        }
      }

      @media #{$sm} {
        display: block;

        & > * {
          width: 100%;

          &:nth-child(1) {
            margin-top: 0;
          }

          &:nth-child(2) {
            margin-top: 15px;
          }

          &:nth-child(2n) {
            margin-left: 0;
          }
        }
      }

      & > li {
        &:hover .contentbox-topic {
          background-color: #ececec;
        }
      }
    }

    & .contentbox {

      & .contentbox-image {

        @media #{$sm} {
          display: block;
        }
      }

      & .contentbox-topic {
        padding: 0 20px;

        @media #{$xs} {
          padding: 0 10px;
        }

        & > .contentbox-title {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 17px;
        }

        & > .contentbox-description {
          font-size: 11px;
          line-height: 15px;
          font-family: var(--regular);
        }
      }
    }
  }

  .ais-Menu {
    padding: 10px 30px 0;

    @media #{$xs} {
      padding: 10px 15px 0;
    }
  }

  .ais-Menu-filters {
    display: flex;
    align-items: center;
    padding-top: 20px;

    @media #{$xs} {
      display: block;
      padding-top: 15px;
    }
  }

  .ais-Menu-list-title {
    font-size: 11px;
    flex: 0 0 auto;
    margin-right: 12px;
    font-family: var(--regular);
    color: $gray-text;

    > .fa {
      margin-right: 8px;
      font-size: 13px;
    }

    @media #{$xs} {
      margin: 0 0 12px;
    }
  }

  .ais-Menu-list {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  .ais-Menu-link {
    color: #9aa7b2;
    border: 1px solid #9aa7b2;
    background-color: $white;
    font-size: 10px;
    font-family: var(--medium);
    height: 22px;
    line-height: 21px;
    border-radius: 15px;
    display: inline-block;
    padding: 0 10px;
    text-transform: uppercase;
    vertical-align: text-top;

    &:hover,
    &:focus {
      cursor: pointer;
      color: $logo-blue;
      border: 1px solid $logo-blue;
    }
  }

  .ais-Menu-item {


    &--selected .ais-Menu-link {
      background-color: $logo-blue;
      border-color: $logo-blue;
      color: $white;
    }
  }
}
