$complete-bg-color: $gold;
$complete-text-color: $gold-text;

.study-unit-content {
  & h2 {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 30px;
    font-family: var(--light);
    color: $logo-blue;
    font-weight: normal;
    clear: both;
  }

  & h3 {
    font-size: 16px;
    font-family: var(--light);
    font-weight: normal;
    color: $logo-blue;
    line-height: 20px;
    margin: 0 0 20px 0;
  }

  & p {
    font-size: 16px;
    font-family: var(--light);
    color: $gray-text;
    line-height: 26px;
    margin: 0 0 20px 0px;

    & a {
      color: $gray-text;
      text-decoration: underline;

      &.btn {
        color: $white;
        text-decoration: none;
        word-break: normal;

        & .fa {
          margin-right: 5px;
        }
      }
    }
  }

  b, strong {
    font-weight: 400;
    font-family: var(--medium);
  }

  & ul, & ol {
    font-size: 16px;
    font-family: var(--light);
    color: $gray-text;
    line-height: 26px;
    margin: 0 0 15px 0;

    & a {
      color: $gray-text;
      text-decoration: underline;
    }
  }

  & ul {
    list-style: disc outside none;
    padding-left: 17px;

    & ul {
      list-style: circle outside none;
    }
  }

  & ol {
    padding-left: 20px;
  }

  .download-button {
    margin-bottom: 20px;

    & > a {
      display: flex;
      margin-right: 0;

      & > .fa {
        margin-right: 5px;
      }
    }
  }

  table.facts-table {
    font-family: var(--light);

    caption {
      font-family: var(--regular);
    }

    td:nth-child(1) {
      font: $medium-font-weight 12px var(--medium);
    }
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;

    & img {
      margin-left: auto;
      margin-right: auto;
    }

    & figcaption {
      text-align: center;
    }
  }

  .align-right {
    text-align: right;

    & img {
      margin-left: auto;
    }

    & figcaption {
      text-align: right;
    }
  }

  .align-justify {
    text-align: justify;
  }

  .highlighted-box {
    background-color: $light-blue;
    border: 1px solid $light-blue;
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .quality-commitment {
    margin-bottom: 20px;
  }
}

// displays the line next to the the learning path
.learning-path {
  margin-bottom: 50px;
  position: relative;
  padding-left: 20px;

  @media #{$md} {
    padding-left: 0;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    background-color: $gray-border;
    height: calc((100% + 1px) - var(--su-learning-path-offset));
    transition: background-color .5s ease;

    @media #{$md} {
      top: 0;
      left: -16px;
    }

    @media #{$xs} {
      top: 0;
      left: -15px;
    }
  }

  // TODO: delete I think?
  &.completed {
    &:before {
      background-color: $complete-bg-color;
    }
  }

  & > h2 {
    // this is for the article editor
    counter-increment: section-number;
  }

  & > section {
    position: relative;
    padding-bottom: 20px;
    counter-increment: section-number;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .mark-complete button {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .complete-su-box-wrapper {
    position: relative;

    &::before {
      content: "";
      height: 2px;
      width: 20px;
      background-color: $gray-border;
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translateX(-50%);

      @media #{$md} {
        width: 16px;
        left: -8px;
      }
    }

    &:has(.complete)::before {
      background-color: $complete-bg-color;
    }
  }

  .complete-su-box {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    font-family: var(--light);

    @media #{$sm} {
      padding: 15px;
    }

    &.complete {
      background: $complete-bg-color;
      @include gold-shimmer(10s);

      & .icon {
        color: #B1800B;
      }

      & .title {
        color: $white;
        font-family: var(--medium);
      }

      & .description {
        color: $gold-text;
      }
    }

    &.incomplete {
      background-color: $logo-blue-bg;
      border: 1px solid $gray-border;
    }

    & .title {
      font-size: 20px;
      color: $logo-blue;
      margin-bottom: 4px;

      @media #{$sm} {
        font-size: $standard-font-size;
      }
    }

    & .description {
      font-size: $small-font-size;
      color: $gray-text;

      @media #{$sm} {
        font-size: $tiny-font-size;
      }
    }

    & .icon {
      position: relative;
      font-size: 28px;
      color: $gold;

      @media #{$sm} {
        font-size: 24px;
      }
    }

    & .icon-star {
      position: absolute;
      top: -5px;
      right: 0;
    }

    & .cta {
      margin: 16px 0 0;
    }

    &.stag-bg--gold {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}

.completed {
  & .learning-path {
    &:before,
    h2:before,
    h2:after,
    & .complete-su-box-wrapper:before {
      background-color: $complete-bg-color;
    }

    h2:before {
      color: $complete-text-color;
      border: none;
    }
  }
}

.complete-tag-tooltip {
  display: none;

  &.show {
    display: block;
    width: 200px;
  }
}

// css for the learning path headline including the cycles
.learning-path > section > h2,
.learning-path > h2 {
  font-family: var(--regular);
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0 0 15px 51px;
  position: relative;
  transition: all .25s ease;

  @media #{$md} {
    margin-left: 0;
  }

  &:before {
    content: counter(section-number);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 -56px;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: 2px solid $gray-border;
    color: #cbcfd2;
    background-color: $white;
    font-size: 20px;
    font-style: normal;
    transition: all .25s ease;
    // fixes heading alignment of counter and text for linux
    padding-top: 1px;

    @media #{$md} {
      margin-left: 0;
      height: 32px;
      width: 32px;
      font-size: 14px;
      line-height: 32px;
    }
  }

  &:after {
    content: "";
    height: 2px;
    width: 15px;
    background-color: $gray-border;
    position: absolute;
    top: 20px;
    left: -70px;

    @media #{$md} {
      width: 16px;
      left: -16px;
      top: 14px;
    }
  }

  & > span {
    position: relative;
    // fixes heading alignment of counter and text for linux
    top: -1px;
  }
}

.learning-path.completed > section > h2,
.learning-path.completed > h2 {

  &:before {
    border-color: $complete-bg-color;
    color: $complete-text-color;
  }

  &:after {
    background-color: $complete-bg-color;
  }
}

.learning-path > .well-done h2 {
  font-family: var(--regular);
  font-size: 16px;
  line-height: 43px;
  padding-left: 51px;
  position: relative;

  @media #{$md} {
    line-height: 31px;
    padding-left: 41px;
    padding-top: 1px;

    & .fa.fa-check {
      top: 7px;
    }
  }

  & > .fa {
    margin-right: 5px;
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all .25s ease;

    &:before {
      font-size: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      border: 2px solid $gray-border;
      color: #cbcfd2;
      background-color: $white;
      padding-top: 2px;
      transition: all .25s ease;

      @media #{$md} {
        height: 32px;
        width: 32px;
        font-size: 14px;
        padding-top: 0;
      }
    }
  }

  &:after {
    content: "";
    height: 2px;
    width: 19px;
    background-color: $gray-border;
    position: absolute;
    top: 21px;
    left: -19px;
    transition: background-color .25s ease;

    @media #{$md} {
      width: 16px;
      left: -16px;
      top: 14px;
    }
  }
}

.learning-path .mark-complete h2 {
  box-shadow: 0 0 0 2px $logo-blue;
  padding-right: 20px;
  padding-left: 40px;
  border-radius: 100px;
  transition: box-shadow .5s ease;

  .fa {
    margin-right: 0;

    &::before {
      border: 0;
      transition: color .5s ease;
    }
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px $logo-blue;

    .fa::before {
      color: $logo-blue;
    }
  }
}

.learning-path:not(.completed) .mark-complete h2 {
  box-shadow: 0 0 0 2px $gray-border;
}

.learning-path.completed > .well-done h2 {
  background-color: $complete-bg-color;
  color: $complete-text-color;

  & > .fa {
    &:before {
      background-color: $complete-bg-color;
      color: $complete-text-color;
    }
  }
  &:after {
    background-color: $complete-bg-color;
  }
}

// Registration wall

.l-main-wrapper.blur-content {
  position: relative;
  padding-bottom: 50px;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, .5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .l-main {
    filter: blur(6px) grayscale(1);
    position: relative;
    max-height: 150vh;
    overflow: hidden;

    &::before {
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $white 100%);
      position: absolute;
      bottom: 0;
      height: 25vh;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  & + footer {
    margin-top: 0;
  }
}

.modal.su-registration-modal {
  position: absolute;
  top: 230px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0 !important;
  text-align: center;
  color: $gray-text;
  padding: 30px 30px 15px;
  z-index: 1;
  overflow: visible;
  max-height: none;

  & img {
    @media #{$sm} {
      width: 80px;
      height: 80px;
    }
  }

  & .btn {
    margin: 0 0 5px;
  }

  & .modal-title {
    font-size: $lead-font-size;
    line-height: 1.2;
    margin: 15px 0 5px;

    @media #{$sm} {
      margin-top: 5px;
    }
  }

  & .modal-footer {
    padding: 0;
    border: 0;
    bottom: 0;
  }

  & ul {
    display: inline-block;
    text-align: left;
    margin: 20px auto 30px;
    list-style: none;
  }

  & li {
    display: flex;
    align-items: center;

    + li {
      margin-top: 20px;
    }

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }

  .circle {
    margin: 0 10px 0 0;
  }

  @media #{$md} {
    top: 125px;
  }
}

.study-unit-footer {

  .btn {
    height: auto;
    min-height: 45px;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 20px;
    text-align: left;
  }

  .btn__subtitle {
    font-family: var(--medium);
    font-size: $tiny-font-size;
    margin-bottom: 2px;
  }

  .btn__text {
    font-family: var(--regular);
    font-size: 12px;
    text-transform: initial;
  }

  .btn--next {
    align-items: flex-end;
    text-align: right;
    margin-right: 0;
  }
}

.subox--blank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px dashed $gray-border;
  text-align: center;
  padding: 40px;

  @media #{$sm} {
    padding: 20px;
  }

  & > .description {
    font-size: $small-font-size;
    margin-bottom: 0;

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }
}

.containers.components .content .subox--blank .title {
  color: $logo-blue;
  font-size: $lead-font-size;
  font-family: var(--light);
  margin-bottom: 24px;

  @media #{$sm} {
    margin-bottom: 14px;
    font-size: $standard-font-size;
  }
}

// A/B Test: New Premium Banner on study units
.modal.premium-banner-modal.premium-banner-modal {
  text-align: center;
  color: $gray-text;
  padding: 30px 50px;

  & * {
    text-wrap: pretty;
  }

  @media #{$sm} {
    padding: 30px 30px;
  }

  & img {
    width: 80px;
    height: 80px;
  }

  & .btn {
    justify-content: space-between;
    align-items: center;
    text-transform: none;
    padding: 12px;
    height: auto;
    width: 280px;
    max-width: 100%;
    gap: 8px;
    font-size: $standard-font-size;

    & .btn-content {
      flex: 1 0 auto;
      text-align: left;
    }

    & .main {
      font-family: var(--regular);
      font-size: $small-font-size;
    }

    & .secondary {
      font-size: $smallest-font-size;
      font-family: var(--light);
    }
  }

  & .modal-header {
    position: relative;
    border-bottom: 0;
    margin: -30px -50px 30px;
    background-color: $logo-blue-bg;
    background-image: url("~images/ab_test_premium_banner_header_bg.webp");
    background-size: auto max(110px, 0);
    background-repeat: no-repeat;
    background-position: center;
    height: 165px;

    @media #{$sm} {
      margin: -30px -30px 30px;
    }
  }

  & .close {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
    width: 15px;
    height: 15px;
    padding: 0px;
    cursor: pointer;
    background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center;
    background-size: contain;
  }

  & .subtitle {
    font-size: $small-font-size;
    line-height: 1.5;
    margin: 0 0 4px;
    color: $gray-text-light;

    @media #{$xs} {
      font-size: $tiny-font-size;
    }
  }

  & .modal-title {
    font-size: 28px;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 20px;

    @media #{$xs} {
      font-size: $lead-font-size;
    }
  }

  & .banner {
    background-color: $logo-blue-bg;
    border-radius: 2px;
    color: $logo-blue;
    text-align: center;
    justify-content: center;
    font-family: var(--medium);

    @media #{$sm} {
      margin-bottom: 30px;
    }
  }

  & .modal-footer {
    padding: 0;
    border: 0;
    bottom: 0;
  }

  & ul {
    display: inline-block;
    text-align: left;
    margin: 0 auto 30px;
    list-style: none;
  }

  & li {
    display: flex;
    align-items: center;

    + li {
      margin-top: 15px;
    }

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }

  .circle {
    margin: 0 10px 0 0;
  }
}
