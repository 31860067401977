@keyframes fade_in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes expandHeight {
  0% {
    max-height: 115px;
    opacity: 0;
  }

  100% {
    max-height: 1000px;
    opacity: 1;
  }
}

// Star Background animations

.stag-bg--gold .star-bg path {
  fill: $gold-bg-light;
}

.star-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .star {
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .star-1 {
    animation-name: star;
    animation-duration: 2s;
    animation-delay: 1s;
  }

  .star-2 {
    animation-name: star;
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .star-3 {
    animation-name: star;
    animation-duration: 2s;
  }
}

@keyframes star {
  to {
    zoom: 10%;
    opacity: 0;
  }

  from {
    zoom: 100%;
    opacity: 1;
  }
}

// Gold shimmer

@keyframes shimmer {
  10% {
    -webkit-mask-position: right;
  }
  10%,
  100% {
    -webkit-mask-position: left;
  }
}
