.fullscreen-layer {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #FFF;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  white-space: nowrap;

  .fullscreen-gallery {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    width: 100%;

    @media #{$md} {
      flex-direction: column;
    }

    & .fullscreen-info {
      white-space: initial;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding: 0 10px;
      align-self: center;
      color: $gray-text;

      @media #{$md} {
        justify-content: flex-start;
        height: 100%;
      }

      & .term__primary, .title {
        font-size: $tiny-font-size;
        font-family: var(--medium);
        line-height: 16px;
      }

      & .description,
      & .term-description {
        display: block;
        margin-top: 5px;
      }
    }

    & .bottom-caption {
      display: none;
      font-size: $tiny-font-size;
      text-align: left;
      background-color: $gray-bg;
      border-top: 1px solid $gray-border;
      color: $gray-text;

      @media #{$md} {
        display: flex;
        max-height: 20vh;
      }

      & .fullscreen-info {
        padding: 10px 15px;

        @media #{$md} {
          overflow-y: auto;
        }
      }
    }

    & .fraction-pagination {
      display: flex;
      align-items: center;
      margin-left: auto;
      border-left: 1px solid $gray-border;
      padding: 15px 10px;
      font-family: var(--medium);
      font-size: 12px;
      color: $gray-text;
    }

    & > .main-gallery {
      flex: 1 1 auto;
      height: 100%;
      overflow: hidden;


      & > .swiper {
        flex: 1;
        height: 100%;

        .swiper-pagination {
          display: none;
          bottom: 20px;

          @media #{$md} {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          & > .swiper-pagination-bullet {
            margin: 5px 5px 0 5px;
            padding: 5px;
            background-color: #fff;
            border: 1px solid $logo-blue;
            border-radius: 50%;
            opacity: 1;

            &.swiper-pagination-bullet-active {
              background-color: $logo-blue;
            }
          }
        }

        & .swiper-slide {
          position: relative;

          & > .image__placeholder, & > .image__hd {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-color: white;
            transition: opacity 1s ease;
          }

          & > .image__placeholder {
            z-index: 1;
            opacity: 1;
          }

          & > .image__hd {
            z-index: 2;
            opacity: 0;

            &.loaded {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .fullscreen-single-image {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;

    // centers the img vertically
    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    & img {
      width: 100%;
      vertical-align: middle;
      position: absolute;
      top: 0;
      left: 0;

      &.image__placeholder {
        z-index: 1;
        opacity: 1;
      }

      &.image__hd {
        z-index: 2;
        opacity: 0;

        &.loaded {
          opacity: 1;
        }
      }
    }
  }

  & > .close {
    top: 10px;
    right: 10px;
    position: fixed;
    width: 19px;
    height: 18px;
    cursor: pointer;
    z-index: 1001;
    padding: 15px;
    background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $gray-bg-dark;
  }

  .image-thumbnails {
    display: flex;
    margin: 0;
    flex: 0 0 auto;
    max-width: 320px;
    background-color: $gray-bg;
    border-right: 1px solid $gray-border;
    height: 100%;
    padding: 0;
    position: relative;
    z-index: 100;

    @media #{$md} {
      position: absolute;
      left: -320px;
      transition: left 400ms;

      .fullscreen-info {
        justify-content: center;
      }
    }

    @media #{$xs} {
      max-width: calc(100vw - 50px);
      left: -479px;
    }

    &.open {
      left: 0;
    }

    & .swiper-slide {
      border-bottom: 1px solid $gray-border;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex: 0;
      padding: 10px;
      box-sizing: border-box;
      align-items: start;
      height: auto;

      & .fa-volume-up, .term-description, .description {
        display: none;
      }

      &.swiper-slide-thumb-active {

        background-color: $white;
        max-height: none;

        & .description,
        & .term-description {
          display: block;
        }

        & .fa-volume-up {
          display: inline-block;
        }

        & .image {
          border-color: $logo-blue;
        }
      }

      .image {
        background-color: $white;
        display: block;
        border: 1px solid $gray-border;
        flex: 0 0 70px;
        height: 70px;

        & > img {
          height: 100%;
          width: 100%;
          aspect-ratio: 1;
        }
      }

      @media #{$md} {
        & .description.description {
          display: none;
        }
      }
    }
  }

  .image-thumbnails-handle {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 15px;
    cursor: pointer;
    display: none;
    z-index: 10;
    font-size: 26px;
    color: $gray-border-dark;

    @media #{$md} {
      display: block;
    }
  }

  & .perspective {
    top: auto;
    bottom: 15px;
    padding-bottom: 0;
  }
}
