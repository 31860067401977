// SLIDEOUT MENU (mobile)

.slideout-open {

  .middlebar {
    display: none;
  }

  .slideout-menu {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    left: auto;
    background-color: #304352;
  }
}

// that the menu dont get shown at the opening
#panel {
  background: #ffffff;
  // overwrites will-change value set by .slideout-panel
  will-change: auto;
}

.l-navigation-mobile .nav-mobile {
  padding: 0 20px;
  border-radius: 2px;

  & > .divider {
    margin: 20px 0;
    border-color: #506c84;
  }

  & .fa {
    height: 14px;
    width: 17px;
    text-align: center;
  }

  & ul {
    list-style-type: none;

    & li {
      margin-bottom: 12px;

      & ul {
        & li {
          &:first-child {
            margin-top: 15px;
          }
        }
      }
    }
  }

  & > ul {
    list-style-type: none;
    margin: 0;
    color: #ffffff;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #506c84;
    font: $medium-font-weight 14px var(--medium);

    & > li {
      margin-bottom: 20px;

      &.opened {
        & > ul {
          max-height: 1000px;
        }
      }

      & > ul {
        max-height: 0;
        transition: max-height .45s linear;
        overflow: hidden;
        margin-left: 32px;

        & > li {
          margin-top: 12px;

          &:first-child {
            margin-top: 15px;
          }
        }
      }
    }

    & a {
      flex: 1;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;

      &.active {
        color: $logo-blue;
      }

      & > .fa {
        margin-right: 10px;
      }

      &.btn > .fa {
        margin-right: 2px;
      }
    }

    & .nav-mobile__title {
      display: flex;
      flex-direction: row;

      & > a {
        white-space: nowrap;
      }

      & svg {
        top: 0.2em;
      }
    }

    & .fa-caret-right, .fa-caret-down {
      margin-top: 3px;
      cursor: pointer;
    }
  }

  &__anchor {
    display: block;
    color: #ffffff;
    font: $medium-font-weight 14px var(--medium);
    margin-bottom: 20px;

    &.active {
      color: $logo-blue
    }

    & .fa {
      margin-right: 14px;
    }
  }

  &__topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > a.btn {
      margin-left: 5px;
    }

    &-text {
      font-family: var(--medium);
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-text, .btn {
      color: $white;
      height: 26px;
      line-height: 26px;
      margin-top: 20px;
      white-space: nowrap;
      word-wrap: normal;
    }
  }
}

.nav-mobile__link-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .nav-mobile__anchor {
    margin: 0;
  }
}

.nav-mobile__link-group-title {
  flex: 1 0 100%;
  opacity: 0.5;
  color: $white;
  font-size: 12px;
  text-transform: uppercase;
  font-family: var(--medium);
}

// BREAKINGBAR
// -----------

.breaking-cta {
  background-color: $dark-bg-blue;
  padding: 80px 0;
  text-align: center;

  @media #{$xs} {
    padding: 30px 0;
  }

  > a {
    margin-right: 0;
  }

  h2 {
    color: #fff;
    margin-bottom: 41px;

    @media #{$xs} {
      font-size: 24px;
      line-height: 30px;
    }
  }

  & p {
    color: #fff;
  }

  .cta-secondary {
    display: block;
    margin-top: 43px;
    color: #fff;
     a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

body.home .breaking-cta {
  & p {
    font-family: var(--regular);
    color: #fff;
    margin-bottom: 20px;
  }
}

// slideout adds and "overflow: hidden" to the body when it is open
// which somehow prevents the existing height: 100%" to work properly
.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: inherit;
}
