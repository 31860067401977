// inspired by https://github.com/twbs/bootstrap/blob/master/scss/_popover.scss

$zindex-popover: 1000;
$popover-bg: $gray-bg-dark;
$popover-arrow-width: 20px;
$popover-arrow-height: 10px;
$popover-border-width: 1px;
$popover-arrow-outer-color: $gray-border;
$popover-arrow-color: $white;
$popover-header-bg: $white;

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: block;
  max-width: 280px;
  word-wrap: break-word;
  background-color: $popover-bg;
  border: 1px solid #dadada;
  border-radius : 2px;
  padding: 10px;
  text-align: center;

  &.image-popover {
    & .popover__content {
      padding: 0;

      & img {
        width: 218px;
        height: 218px;
      }
    }
  }

  @media #{$xs} {
    padding: 13px;
  }

  &.popover--atlas {
    top: 50%;
    left: 50%;
    display: block;
    border-color: $logo-blue;
    margin-left: -140px;
    margin-top: -100px;
  }

  & .popover__close {
    top: 12px;
    position: absolute;
    cursor: pointer;
    right: 12px;
    color: $logo-blue;
    font-size: 11px;
    text-decoration: underline;
    font-family: var(--light);
  }

  & .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  & .popover__title {
    background-color: #FFF;
    padding: 20px 20px 0 20px;
    font-family: var(--light);
    font-size: 17px;
    line-height: 21px;
    color: $logo-blue;

    @media #{$xs} {
      padding: 13px 7px 0 7px;
      font-family: var(--regular);
      font-size: 13px;
      line-height: 18px;
    }

    &:empty {
      display: none;
    }
  }

  &[data-popper-placement^="top"] {
    .arrow {
      bottom: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    }

    .arrow::before,
    .arrow::after {
      border-width: $popover-arrow-height ($popover-arrow-width * 0.5) 0;
    }

    .arrow::before {
      bottom: 0;
      border-top-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      bottom: $popover-border-width + 1;
      border-top-color: $popover-arrow-color;
    }
  }

  &[data-popper-placement^="right"] {
    .arrow {
      left: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
      width: $popover-arrow-height;
      height: $popover-arrow-width;
    }

    .arrow::before,
    .arrow::after {
      border-width: ($popover-arrow-width * 0.5) $popover-arrow-height ($popover-arrow-width * 0.5) 0;
    }

    .arrow::before {
      left: 0;
      border-right-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      left: $popover-border-width + 1;
      border-right-color: $popover-arrow-color;
    }
  }

  &[data-popper-placement^="bottom"],
  &[x-placement^="bottom"] {
    .arrow {
      top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    }

    .arrow::before,
    .arrow::after {
      border-width: 0 ($popover-arrow-width * 0.5) $popover-arrow-height ($popover-arrow-width * 0.5);
    }

    .arrow::before {
      top: 0;
      border-bottom-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      top: $popover-border-width + 1;
      border-bottom-color: $popover-arrow-color;
    }

    // This will remove the popover-header's border just below the arrow
    .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: $popover-arrow-width;
      margin-left: -($popover-arrow-width * 0.5);
      content: "";
      border-bottom: $popover-border-width solid $popover-header-bg;
    }
  }

  &[data-popper-placement^="left"] {
    .arrow {
      right: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
      width: $popover-arrow-height;
      height: $popover-arrow-width;
    }

    .arrow::before,
    .arrow::after {
      border-width: ($popover-arrow-width * 0.5) 0 ($popover-arrow-width * 0.5) $popover-arrow-height;
    }

    .arrow::before {
      right: 0;
      border-left-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      right: $popover-border-width + 1;
      border-left-color: $popover-arrow-color;
    }
  }

  & .popover__content {
    padding: 20px;
    background-color: $white;

    @media #{$xs} {
      padding: 13px;
    }

    & p {
      font-family: var(--light);
      font-size: 14px;
      line-height: 21px;
      color: $gray-text-light;
      margin-bottom: 18px;
      white-space: normal;

      @media #{$xs} {
        font-family: var(--regular);
        font-size: 11px;
        line-height: 16px;
        color: $gray-text-light;
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    & a {
      display: block;
      font-size: 14px;
      font-family: var(--light);
      color: $logo-blue;
      line-height: 14px;
      text-decoration: underline;

      @media #{$xs} {
        font-family: var(--regular);
        font-size: 11px;
        line-height: 11px;
      }
    }
  }
}
