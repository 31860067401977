.custom-quiz-training-session {
  border: 1px solid $gray-border;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 40px;

  & .custom-quiz-training-session__heading {
    font-size: $tiny-font-size;
    font-family: var(--regular);
    color: $gray-text;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  & > .custom-quiz-training-session__header {
    border-bottom: 1px solid $gray-border;
    height: 101px;

    & > .custom-quiz-training-session__info {
      padding: 0 30px;
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      @media #{$sm} {
        justify-content: "center"
      }


      & p {
        margin-right: 20px;

        @media #{$sm} {
          display: none;
        }

        & a {
          text-decoration: underline;
          color: $gray-text;
          white-space: nowrap;
        }
      }

      & .btn.btn--white {
        margin-right: 0;

        @media #{$sm} {
          width: 100%;
        }
      }
    }
  }

  & > .custom-quiz-training-session__main {
    background-color: $gray-bg;
    position: relative;

    & > #custom_quiz_results_container {
      min-height: 193px;
    }
  }
}

.custom-quiz-results__heading {
  margin: 10px 0 5px;
}
