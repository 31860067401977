.tooltip {
  left: 0;
  z-index: 100000;
  background-color: $dark-bg-blue;
  color: white;
  padding: 10px;
  width: auto;
  font-size: 13px;
  font-family: var(--medium);
  line-height: 18px;
  text-align: center;
  border-radius: 3px;
  position:absolute;
  text-transform: initial;

  & > .arrow,
  & > .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  & > .arrow {
    visibility: hidden;
  }

  & > .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    left: 0;
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -4px;
  }

  // used for tooltips which don't use popper
  .enable-tooltip & {
    display: none;
    margin-top: 4px;
    margin-left: 50%;
    transform: translateX(-50%);

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 5px 6px;
      border-color: transparent transparent $dark-bg-blue transparent;
      top: -5px;
      position: absolute;
      left: 82px;
    }

    &--top {
      margin-top: -48px;

      &:after {
        border-width: 5px 6px 0 6px;
        border-color: $dark-bg-blue transparent transparent transparent;
        top: 38px;
        left: 0;
      }
    }
  }

  .enable-tooltip:hover & {
    display: block;
  }
}

.bookmark-tooltip, .completed-item-tooltip {
  width: 176px;
}
