.trial-clock {
  text-align: center;

  & .trial-clock__headline {
    font-size: 29px;
    font-size: 38px;
    color: $gray-text;
    margin-bottom: 20px;
    font-family: var(--light);
    text-align: center;

    @media #{$sm} {
      font-size: 22px;
      line-height: 28px;
    }

    @media #{$xs} {
      font-size: 18px;
      line-height: 20px;
    }

    & > strong {
      color: $logo-blue;
      font-family: var(--regular);
    }

    & a {
      color: $gray-text;
      text-decoration: underline;
    }
  }

  & .trial-clock__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-text;
    font-family: var(--light);
    font-size: 22px;
    margin-bottom: 20px;

    @media #{$sm} {
      font-size: 17px;
      line-height: 25px;
    }

    @media #{$xs} {
      font-size: 12px;
      line-height: 16px;
    }

    & > .counter__delimiter {
      font-size: 60px;
      margin-right: 9px;

      @media #{$md} {
        font-size: 40px;
        margin-right: 5px;
      }
      @media #{$xs} {
        font-size: 20px;
        margin-right: 2px;
      }
    }

    & > .counter.counter__digit {
      @media #{$xs} {
        margin-right: 2px;
      }
    }
  }

  & .btn-big {
    display: inline-flex;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  & .trial-meta-text {
    font-size: 12px;
    color: #495354;
    text-align: center;
    line-height: 1.2;
  }
}

.trial-clock-small {

  & > a {
    color: $red;
  }

  & .counter__digit {
    font-variant-numeric: tabular-nums;
  }

  .sidebar & {
    & > .trial-clock__counter {
      display: block;
      font-size: 14px;
      font-family: var(--medium);
      line-height: 20px;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $gray-border;
    }
  }

  .l-topbar-mobile & {
    font-size: 12px;
    font-family: var(--regular);
    line-height: 18px;
  }
}
