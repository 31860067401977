//
// Overwrites _article_essentials after fonts have loaded

article .article-infobox,
.article-content .article-infobox {
  padding: 32px 30px;
  border: none;
  background-color: $dark-bg-blue;
  color: $white;
  font-size: 16px;
  line-height: 26px;
  border-radius: 2px;
  margin-bottom: 30px;
  overflow: auto;
  clear: both;

  @media #{$xs} {
    padding: 22px 20px;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & h2 {
    font-size: 24px;
    line-height: 39px;
    padding-top: 0;
    margin-top: 0;
    color: $white;
    border-top: none;

    & > .fa.fa-lightbulb-o:before {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  & h3 {
    font-family: var(--regular);
    font-size: 18px;
    line-height: 29px;
    color: $white;
    margin-bottom: 10px;
  }

  & h4 {
    font-family: var(--regular);
    color: $white;
  }

  & p {
    font-size: 16px;
    line-height: 26px;
    color: $white;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $white;

      &:hover {
        color: $logo-blue;
      }
    }
  }

  & ol, & ul {
    color: $white;

    & a {
      color: $white;

      &:hover {
        color: $logo-blue;
      }
    }
  }

  section + section {
    margin-top: 30px;
  }

  & section:last-child {
    margin-bottom: 0;
  }
}

article .article-meta-content,
.article-content .article-meta-content {
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: $gray-text;
  font-size: 14px;
  line-height: 20px;
  margin-top: 50px;
  padding: 20px;
  clear: both;

  h1, h2, h3 {
    padding-top: 10px;
    margin-top: 0;
    border: 0;
  }

  p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 20px;
    font-family: var(--regular);
  }

  a {
    color: $gray-text;
    text-decoration: underline;
  }

  li {
    margin: 0;
  }
}

.reg-wall-modal {
  text-align: center;
  color: $gray-text;
  padding: 30px 50px 15px;

  @media #{$sm} {
    padding: 30px 30px 15px;
  }

  & img {
    width: 80px;
    height: 80px;
  }

  & .btn {
    margin: 0 0 5px;
  }

  & .modal-header {
    position: relative;
    border-bottom: 0;
  }

  & .close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    cursor: pointer;
    background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center;

    @media #{$sm} {
      top: -15px;
      right: -15px;
    }
  }

  & .description {
    font-family: var(--medium);
  }

  & .modal-title {
    font-size: $lead-font-size;
    line-height: 1.2;
    margin: 15px 0;

    @media #{$sm} {
      margin-top: 5px;
    }
  }

  & .modal-footer {
    padding: 0;
    border: 0;
    bottom: 0;
  }

  & ul {
    display: inline-block;
    text-align: left;
    margin: 20px auto 30px;
    list-style: none;
  }

  & li {
    display: flex;
    align-items: center;

    + li {
      margin-top: 20px;
    }

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }

  .circle {
    margin: 0 10px 0 0;
  }
}

article {
  .related-articles, .related-videos {
    list-style: none;
    padding: 0;
    columns: 2;

    & svg {
      align-self: flex-start;
      margin-right: 6px;
      font-size: $tiny-font-size;
      position: relative;
      top: .325em;
      flex-shrink: 0;
    }

    & li a {
      display: flex;
      color: $logo-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    & li {
      margin-bottom: 6px;
      display: inline-block;
      break-inside: avoid-column;
      width: 100%;
    }

    @media #{$sm} {
      columns: unset;
    }
  }
}
